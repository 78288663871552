/* BACK-TO-TOP */

.ksw-top {
  display: inline-block;
  height: 40px;
  width: 40px;

  position: fixed;
  bottom: 40px;
  right: 10px;

  box-shadow: 0 0 10px rgba(#000, .05);

  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;

  background: rgba($color-2, .8) url(/static/landing/img/ksw-top-arrow.svg) no-repeat center 50%;

  visibility: hidden;
  opacity: 0;

  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;

  &.ksw-is-visible, &.ksw-fade-out, .no-touch &:hover {
    -webkit-transition: opacity .3s 0s, visibility 0s 0s;
    -moz-transition: opacity .3s 0s, visibility 0s 0s;
    transition: opacity .3s 0s, visibility 0s 0s;
  }

  &.ksw-is-visible { /* the button becomes visible */
    visibility: visible;
    opacity: 1;
    z-index:9999;
  }

  &.ksw-fade-out { /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
    opacity: .5;
  }

  .no-touch &:hover,
  &:hover {
    background-color: rgba($color-2, 1);
    opacity: 1;
  }

  @include breakpoint(md) {
    right: 20px;
    bottom: 20px;
  }

  @include breakpoint(lg) {
    height: 50px;
    width: 50px;
    right: 30px;
    bottom: 30px;
  }
}