.map-filters {

    ul {
        list-style-type: none;
        li {
            a {
                color: #7b7b7b;
                display: block;
                padding: 10px 15px;
                &:hover {
                    background-color: $lightgray;
                    color: $color-2;
                    text-decoration: underline;
                }
            }
        }
    }
}

.map-cols {

    position: relative;

    #map {
        z-index: 1;
        height: 100vh;
    }

    #buttons {
        margin-top: 30px;
    }

    .map-info {
        position: absolute;
        top: 0;
        right: 350px;
        bottom: 0;
        z-index: 4;
        pointer-events: auto;
        transition: transform .3s cubic-bezier(.4,0,.2,1),visibility 0s .3s,background-color 0s;
        transition-delay: 0s, 0.3s, 0s;
        padding-left: 3.75rem;
        padding-right: 3.75rem;
        background: #fff;
        transition: $transition;

        &.hide {
            padding: 0;
        }

        .close-btn {
          color: $black;
          font-size: 1.2rem;
          position: absolute;
          right: 50px;
          top: 0;
          width: 40px;
          height: 40px;
          cursor: pointer;
          opacity: .9;
          background: $color-3;
          text-align: center;
          border-radius: 50%;

          &:hover {
            opacity: 1;
            text-decoration: none;
            background: #b1ccb4;
          }
        }

        div {
            padding-top: 50px;
        }
    }

}

