.first-section {
    background-color: $lightgray;

    @include breakpoint(md) {
        .wrapper {
            padding-left: 0;
            position: relative;
        }
    }
    @include breakpoint(md) {
        .page-title {
            margin: 0 -400px 0 0;
            padding: 0 0 0 80px;
            border-style: solid;
            border-width: 0 0 0 50px;
            border-color: $color-2;
            width: 80%;
                h1 {
                    font-size: 4vw;
                }
            }
        }
    }

.second-section {
    .wrapper {
        @include breakpoint(md) {
            padding: 0 0 0 80px;
        }
            border-style: solid;
            border-width: 0 0 0 50px;
            border-color: $color-2;
    }
}