.blog-latest-entries {
    article {
        margin-bottom: 40px;
        @include breakpoint(md) {
            margin-bottom: 80px;
        }
    }
    ul.post-detail {
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;
        font-style: italic;
    }

    .post-item.post-detail {
        .blog-visual {
            margin: 25px auto 50px;
        }
    }

    .blog-visual {
        img {
            height: 200px;
            object-fit: cover;
        }
    }
}

.home {

    #news-section {
        .cols-4 {
            grid-gap: 2.5rem;
        }
    }

    .blog-latest-entries {

        article {
           /*  grid-column: 2/3; */
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ddd;
            .post-excerpt {
                display: none;
            }

            img {
                width: 120px;
                object-fit: cover;
                height: 120px;
                transition: all .6s ease-in-out;
            }

            &:hover {
            img {
                transform: scale(1.1);
                }
            }

            h3 {
                font-size: 22px;
                margin: 5px 0 0;
            }
        }

        /*article:first-child {
            grid-column: 1/2;
            grid-row: 1/6;
            .post-excerpt {
                display: block;
            }
            .cols-2-news {
                display: block;
            }
            img {
                height: auto;
                width: 100%;
            }

            h3 {
             font-size: 24px;
            }
        }*/
       /*  article:nth-child(2) {
            grid-row: 1;
        }
        article:nth-child(3) {
            grid-row: 2;
        }
        article:nth-child(4) {
            grid-row: 3;
        }
        article:nth-child(5) {
            grid-row: 4;
        } */
    }
}

.blog-date-wrap {
    display: block;
    margin-top: 5px;
}


.post-item.post-detail {
    @include breakpoint(md) {
        .blog-content {
            padding: 20px 0;
         }
    }
    .blog-date-wrap.wrapper {
        border-left: 5px solid $color-2;
    }
}

.blog-content {
    .post-detail {
        list-style-type: none;
        padding-left: 0;
        display: block;
        width: 100%;
        position: relative;
        overflow: hidden;
        li {
            float: left;
            margin-right: 10px;
        }
    }
}

.news-row {
    position: relative;
    a {
        color: $color-1;
        position: relative;
        cursor: pointer;
        background: 0 0;
        display: block;
        text-decoration: none;
    }
    img {
        -webkit-backface-visibility: hidden;
        -ms-transform: translateZ(0); /* IE 9 */
        -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
        transform: translateZ(0);
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
    }

    h3 {
        color: $color-1;
        margin-top: 0;
    }

    &:hover {
        h3 {
            color: $lightgreen;
        }
    }
}

.app-blog {
    .blog-visual {
        img {
            width: 100%;
        }
    }
}

.blog-visual {
    overflow: hidden;
}

.page-header {
    color: #fff;
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    max-height: 780px;
    padding: 120px 0 20px;
    background: #31423d;
    @include breakpoint(md) {
        padding: 180px 0 80px;
    }
    margin: 0;
     img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: .3;
        height: 100%;
        object-fit: cover;
    }
}

.page-container {
    overflow: hidden;
    padding-bottom: 50px;
}

.blog-date {
    color: rgba(0, 0, 0, 0.54);
}

@media screen and (max-width:480px) {
    article {
        margin-bottom: 40px;
    }
}

.dark-theme {
    background: #2d2d2d !important;
    p, h1, h2, h3, h4, h5, span, a { color: #fff;}
    .blog-date-wrap span { color: $lightgreen; }
}

.blog-list {
    .post-item {
        margin-bottom: 60px;
    }
}