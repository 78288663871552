.card.document {
    padding: 30px;
    margin: 40px 0;
    background: rgba(180, 193, 190, 0.24) url('/static/landing/img/PDF-logo.png') no-repeat 30px center;
    background-size: 60px !important;
    border: 1px solid rgba(204,204,204,.2);
    border-radius: 0;
    .card-body {
        padding-left: 80px;
        align-items: center;
    }
    .btn.btn-primary {
        line-height: 1.9;
        letter-spacing: 0 !important;
    }
    p a {
        background-image: none !important;
        &:hover {
            text-decoration: underline;
        }
    }

    &.file {
        background: rgba(180, 193, 190, 0.24) url('/static/landing/img/archive.svg') no-repeat 30px center;
    }

    &.doc {
        background: rgba(180, 193, 190, 0.24) url('/static/landing/img/doc.png') no-repeat 30px center;
    }
}