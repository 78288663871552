// -----------------------------------------------------------------------------
// This file contains all styles related to the form component.
// -----------------------------------------------------------------------------

input {
    border-radius: 0;
}

label {
    color: $color-2;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 5px;

    font-weight: normal;
    width: 100%;
    line-height: 1rem;
    padding-top: 0.3em;
}

 .ksw-form {
    opacity:1;
    top:20px;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.25, 0.265, .85);
    transition-property:transform,opacity,box-shadow,top,left;
    transition-duration:.5s;
    transform-origin:161px 100%;
    transform:rotateX(0deg);
    position:relative;
    max-width:400px;
    border-top: 2px solid $color-2;
    left:0;
    right:0;
    margin:auto;
    top:0;
    bottom:0;
    padding:50px 0;
    background: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 10px;
    input[type='password']{
        color: $color-2 !important;
    }
    input[type='text'],
    input[type='password'],
    input[type='email'],
    input[type='number'],
    select,
    textarea {
        color: #000;
        width: 100%;
        margin-top:-2px;
        background: transparent;
        left: 0;
        padding: 10px 40px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-right: none;
        border-left: none;
        outline: none;
        box-shadow: none;
      }
    input{
          &:focus{
            background: #fff;
            box-shadow:none;
            outline:none;
          }
          background:transparent;
          transition-property:background,color;
          transition-duration:.2s;
          &:hover{
            transition-property:background,color;
            transition-duration:.2s;
          }
        }
    label {
        padding-left: 40px;
    }

   .secondaryAction {
        border: none;
        padding: 0;
        text-transform: none;
    }
    .ksw-form-btn-wrap {
        margin: 20px 0;
    }

    &.edit-mode {
        input[type='text'],
        input[type='password'],
        input[type='email'],
        input[type='number'],
        select,
        textarea {
            background: $lightblue;
        }
    }
}

.ksw-form-index {
    .ksw-form-btn-wrap {
        margin: 40px 0 10px;
    }
}

.side-content {
    .ksw-form {
        margin: auto 0;
        height: 100%;
    }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #222;
  text-transform: none;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #222;
  text-transform: none;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #222;
  text-transform: none;
}
:-moz-placeholder { /* Firefox 18- */
  color: #222;
  text-transform: none;
}

label[for="id_remember"] {
    width: auto;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea,
select {
    box-shadow: none;
    width: 100%;
    border: 1px solid $color-2;
    line-height: 1.4;
    padding: 7px;


  &:focus {
    border-color: rgba($color-2, 0.9);
    color: $color__text-input-focus;
    outline: 0;
    outline-offset: -4px;
  }
}

textarea {
  box-sizing: border-box;
  display: block;
  height: 150px;
  width: 100%;
  max-width: 100%;
  resize: vertical;

  &.other {
    height: 50px;
  }
}

.submit-btn-wrap {
    margin-top:30px;
}

.poll-form {
    background: #fff;
    padding: 40px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
        overflow: auto;
    }

    ul li {
      color: #AAAAAA;
      display: block;
      position: relative;
      float: left;
      width: 100%;
      height: 100px;
        border-bottom: 1px solid #ddd;
    }

    ul li input[type=radio]{
      position: absolute;
      visibility: hidden;
    }

    ul li label{
        color: #aaa;
      display: block;
      position: relative;

      padding: 25px 25px 25px 80px;
      margin: 10px auto;
      height: 30px;
      z-index: 9;
      cursor: pointer;
      -webkit-transition: all 0.25s linear;
    }

    ul li:hover label{
        color: $color-2;
    }

    ul li .check{
      display: block;
      position: absolute;
      border: 5px solid #AAAAAA;
      border-radius: 100%;
      height: 26px;
      width: 26px;
      top: 30px;
      left: 20px;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;
    }

    ul li:hover .check {
      border: 5px solid $color-2;
    }

    ul li .check::before {
      display: block;
      position: absolute;
        content: '';
      border-radius: 100%;
      height: 12px;
      width: 12px;
      top: 2px;
       left: 2px;
      margin: auto;
        transition: background 0.25s linear;
        -webkit-transition: background 0.25s linear;
    }

    input[type=radio]:checked ~ .check {
      border: 5px solid $lightgreen;
    }

    input[type=radio]:checked ~ .check::before{
      background: $lightgreen;
    }

    input[type=radio]:checked ~ label{
      color: $lightgreen;
    }

    input[type="submit"] {
        margin-top: 25px;
        position: relative;
        z-index: 9999;
    }
}

.errorlist {
    list-style-type: none;
    li {
        color: $red;
    }
}

label[for="id_form-0-DELETE"],
label[for="id_form-1-DELETE"],
label[for="id_form-2-DELETE"],
label[for="id_form-3-DELETE"],
label[for="id_form-4-DELETE"],
label[for="id_form-5-DELETE"] {
    width: auto;
}

.ksw-form-address input[type="checkbox"] {
    position: relative;
    top: 3px;
    left: 10px;
}

#id_index, #id_water_meter {
    width: 98%;
    border: 1px solid #ddd !important;
}

select {
    background-color: transparent;
}

#signup_form {
    padding-bottom: 120px;
    #id_hcaptcha {
        position: absolute;
        bottom: 100px;
        text-align: center;
        width: 100%;
    }
    .submit-btn-wrap {
        position: relative;
        top: 80px;
        z-index: 9999;
    }
}

.fieldErr {
    border: 1px solid red !important;
}

.alert-danger {
    text-align: center;
    max-width: 400px;
    margin: auto;
}