// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

#footer {
    background-color: rgb(25, 45, 42);
    color: #c4c4c4;
    padding: 20px 0;
    @include breakpoint(md) {
        padding: 100px 0;
    }
    position: relative;
    .nav {
        ul {
            list-style-type: none;
            padding-left: 0;
            margin-left: 0;

            li {
                list-style-type: none;

                &.current_page_item a,
                a:hover {
                    color: $color-2;
                }

                a {
                    color: #fff;
                    text-transform: uppercase;

                    line-height: 1.1;
                    position: relative;
                    display: block;
                    padding: 10px 15px;

                    &:hover {
                        background-color: $lightgray;
                        color: $color-2;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .contact-lines {
        strong {
            text-decoration: underline;
        }
        a:not(.social) {
            line-height: 1.7;
            overflow: hidden;
            color: $color-2;

            padding: 1px 2px 0;
            border-bottom: 3px solid #fff;
            &:hover {
                color: $color-2;
                border-bottom: 3px solid #fcba06;
                background: none !important;
            }
        }
    }

    .contact-line-title {
        letter-spacing: 1px;
        padding-bottom: 8px;
        text-transform: uppercase;
    }

    h5 {
      line-height: 1.6;
    }

    .disclaimer {
      border-top: 1px solid #515151;
      padding-top: 40px;
      a {
        color: $lightgreen;
        line-height: 1.6;
      }
      img {
        width: 200px;
      }
    }

    .social-links {
        margin: 30px 0;
        .social {
          color: $color-2;
          opacity: 1;

          line-height: 30px;
          margin: 0px 10px 10px 0px;
          text-align: center;

          &:hover {
            opacity: 0.8;
          }
        }
    }
}

.dashboard {
    #footer {
        background: #2d2d2d;
    }
}

.body-overlay {
    position: fixed;
    background: rgba(0,0,0,0.7);
    height: 100vh;
    width: 100%;
    top: 0;
    display: none;
}

.with-overlay {
    overflow: hidden;
    .body-overlay {
        display: block;
    }
}

.footer-img {
    position: absolute;
    top: -70px;
    filter: invert(0.4) sepia(0.5) saturate(2.1) hue-rotate(118.8deg) brightness(0.67);
    @include breakpoint(md) {
        top: -120px;
    }
    @include breakpoint(xl) {
        top: -180px;
    }
}

.dashboard {
    .footer-img {
        display: none;
    }
}