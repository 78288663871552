// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

#news-section {
    padding: 20px 0;
    @include breakpoint(md) {
        padding: 80px 0;
        .news-articles-wrapper {
            grid-column: 1/3;
        }
    }
}

.calendar {
    background: #fff;
    padding: 20px 0;
    .page-content {
        padding: 40px 0;
    }
    @media screen and (max-width: 610px) {
        .cols-2 {
            display: grid;
            #calendar {
                order: 1;
            }
            .event-details-wrap {
                order: 0;
                margin-bottom: 30px;
            }
        }
    }
    @include breakpoint(md) {
        padding: 80px 0 0;
        .page-content {
            padding: 80px 0;
        }
    }
}

.promo-col {
    @include breakpoint(md) {
        grid-column: 4/5;
    }
}

.poll {
    position: relative;
    background-color: $color-2;
    padding: 20px 0;
    @include breakpoint(md) {
        padding: 100px 0 0;
    }
    .poll-background-overlay {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: .05;
        transition: background .3s,border-radius .3s,opacity .3s;
    }

    .cols-2 > div {
        padding-bottom: 40px;
        @include breakpoint(md) {
            padding-bottom: 100px;
        }
        &:first-child {
            width: 80%;
            align-self: center;
            h2, h4 {
                color: #fff;
            }

            h2 {

            }

            h4 {

                line-height: 1.5;
            }
        }
    }

    .sep {
        height: 1px;
        width: 70px;
        background-color: #cccccc;
        margin: 20px 0;
    }
}


/* Resources */
.resources {
    background: #ebf4f2;
    position: relative;
    padding: 20px 0;
    @include breakpoint(md) {
        padding: 100px 0;
    }
}

/* commons */
.boxed-link {
    height: 270px;
    background: #fff;
    margin: 16px auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    position: relative;
    text-align: center;

    &:hover {
        border-style: solid;
        border-width: 1px;
        box-shadow: 0 0 0 1px #498952,0 0 12px 0 rgba(0,163,189,0.3);
        outline: none;
    }

   a {
        text-decoration: none;
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-decoration: none; /* No underlines on the link */
/*         z-index: 10; Places the link above everything else in the div */
        background-color: #FFF; /* Fix to make div clickable in IE */
        filter: alpha(opacity=1); /* Fix to make div clickable in IE */
    }

    .resource-tile-body {
        min-height: 180px;
        padding: 20px;
    }

    img {
        height: 80px;
    }

    p {
        font-weight: 400;
        color: #425b76;
        text-align: center;
        margin: 0;
    }
}

.home-map {
    iframe {
        border: none;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        @include breakpoint(md){
            width: 40%;
        }
    }
}

.featured-block {
    margin-bottom: 30px;
    @include breakpoint(md) {
        margin-bottom: 0;
    }
    a {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: #FFF;
        filter: alpha(opacity=1);
        &:hover {
            text-decoration: none;
        }
    }


    position: relative;
    display: block;
    background: #fff;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.07);
    transition: all 300ms ease;
    .inner-box {
        padding: 25px 30px;
    }
    @include breakpoint(md) {
        .inner-box {
            padding: 40px 30px 25px;
        }
    }

    &:hover {
        box-shadow: 0 0 30px 0 rgba(0,0,0,.07),0 15px 30px 0 rgba(0,0,0,.07);
    }

    .icon-box .lnr {
        font-size: 3rem;
        color: $color-2;
    }



    p {
        font-weight: 400;
        color: #757575;
    }

    .content {
         h3 {
            color: $color-1;
            &:hover {
                color: $color-2;
                text-decoration: none;
            }
        }
    }

    .read-more2 {
        position: relative;
        display: block;
        padding: 15px 0 0;
        border-top: 1px solid rgba(0,0,0,.1);
        margin-top: 25px;
        @media screen and (min-width: 1200px) {
            .cols-2 {
                grid-template-columns: 150px auto;
            }
        }
        span {
            color: #757575;

            &.right {
                text-align: right;
            }
        }
        &:hover {
            text-decoration: none;
            span {
                color: $color-2;
            }
         }
    }
}

.all-events {
    display: none;
}

.home {
    .all-events {
        display: inline-block;
    }
}