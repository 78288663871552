[data-toggle="collapse"] {
    color: #212121;
    position: relative;

    cursor: pointer;
    &:after {
        content: '+';
        position: absolute;
        right: 30px;
        top: 0;
        line-height: 1;
    }

    &:hover {
        color: $color-2;
         &:after {
            color: $color-2;
         }
    }
}

.collapse.in {
    margin: 20px 0;
}

div[data-children=".card"] {
    border-bottom: 2px solid #212121;
    margin: 30px 0 20px;
    &:hover {
        border-color: $color-2;
    }
    &:first-of-type {
        border-top: 2px solid #212121;
        padding-top: 20px;
    }
}

div[data-toggle="collapse"][aria-expanded="true"] {
    border-bottom: 2px solid $lightgray;
    &:after {
        content: '-';
    }
}