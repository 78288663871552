// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home's first section.
// -----------------------------------------------------------------------------


.header-section {
  @include breakpoint(md) {
    background-attachment: fixed;
    overflow: hidden;
    height: 20vh;
  }
  overflow: hidden;
  background-color: #000;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  .header-content {
    @include breakpoint(md) {
      position: absolute;
    }
    display: block;
    overflow: hidden;
    width: 100vw;
    height: 100%;
    @include breakpoint(md) {
      height: 20vh;
    }
    margin-right: auto;
    margin-left: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
  }

  .header-text {
    color: #fff;
    position: relative;
    z-index: 50;
    @include breakpoint(md) {
        height: 100vh;
    }
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    top: auto;
    @include breakpoint(md) {
         &.active {
            display: block;
         }
    }
  }
  @include breakpoint(md) {
      .header-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        background-position: 50%;
        background-size: cover;
        overflow: hidden;
      }

      .header-video {
        height: 50vh;
        min-width: 100vw;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
  }


  .menu {
    position: absolute;
    z-index: 100;
    width: 100vw;
    box-shadow: none;
    line-height: 10px;

    .wrap.cc-menu {
      padding: 20px 40px 25px;
      z-index: 900;
      width: 100vw;
      @include breakpoint(md) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 20px 40px 25px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        grid-column-gap: 20px;
        grid-row-gap: 16px;
        -ms-grid-columns: 4fr 1fr;
        grid-template-columns: 4fr 1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
      }
      background-color: transparent;

    }
  }
}

.black-transp {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
/*  background-color: rgba(0, 0, 0, 0.5);*/
    background: linear-gradient(to bottom, rgba(0,0,0,0) 35%, rgba(39,57,56,1) 99%);
}

@include breakpoint(md) {

  .header-section {
    height: 100vh;

    .header-content {
      height: 100vh;
    }

    .header-text {
      height: 100vh;
      text-align: left;
      display: none;
      &.active {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
      }
    }

    .header-video {
      height: 100vh;
    }
  }

  .home .header-section,
  .header-section {
    height: 100vh;

    .header-content {
      height: 100vh;
    }

    .header-text {
      height: 100vh;
    }
    .header-text2 {
      height: 100%;
    }
  }
}

.header-headline {
    @media screen and (max-width: 900px) {
        h1 {
            font-size: 15px;
            position: absolute;
            color: #fff;
            font-weight: bold;
            z-index: 999;
            top: -90px;
            width: 100%;
        }
    }
}

 @media screen and (max-width: 900px) {
    .header-section {
        video {
            width: 100%;
            margin-top: 70px;
        }
    }
 }

.header-headline-wrap {
    @include breakpoint(md) {
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}

.intro-icon {
    border-radius: 50%;
    border: 1px solid white;
    padding: 10px;
    @media screen and (max-width: 900px) {
        img {
            width: 30px;
            height: 30px;
        }
   }
   @include breakpoint(xl) {
       padding: 20px;
   }
    width: auto;
    display: inline-block;
    margin-bottom: 10px;
    text-align: center;
}

.intro-cols {
    border-top: 1px solid rgba(255,255,255,.2);
    .intro-columns > div {
       border-right: 1px solid rgba(255,255,255,.2);
       transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
       position: relative;
       @media screen and (max-width: 900px) {
           height: 90%;
           margin-bottom: 15px;
       }
       @include breakpoint(md){
            border: none;
            border-right: 1px solid rgba(255,255,255,.2);
       }
       @include breakpoint(xl) {
         padding: 30px 40px;
       }
       h5 {
        color: #fff;
        cursor: pointer;
        a {
            color: #fff;
            cursor: pointer;
            &:hover {
                text-decoration:underline;
            }
        }
       }
    }
    p, p a {color: #fff !important;}

    .intro-col1 {
         border-left: 1px solid rgba(255,255,255,.2) !important;
          &:hover {
            background-color: $lightgreen;
          }
    }

    .intro-col2 {
         &:hover {
            background-color: #40a085;
         }
    }

    .intro-col3 {
         &:hover {
            background-color: #377561;
         }
    }
    @media screen and (max-width: 900px) {
        .intro-col1,
        .intro-col2,
        .intro-col3 {
            position: relative;
            top: 10px;
            margin: auto;
            width: 100%;
        }
    }

    a:hover {
        text-decoration: none;
    }
    div {
        align-self: center;
    }
}

.w-background-video {
  position: relative;
  overflow: hidden;
  height: 500px;
  color: white;

  > video {
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
    z-index: -100;

  }
}

.o-background, .o-background.-overlay::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.o-background {
    display: block;
    background-position: 50%;
    background-size: cover;
    overflow: hidden;
}

.o-background_video {
    @include breakpoint(md) {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        transform: translateX(-50%) translateY(-50%);
        vertical-align: middle;
        display: inline-block;
    }
}

@include breakpoint(md){
    .header-text-main {
        position: absolute;
        width: 100%;
        top: 40%;
        z-index: 9999;
        h1 {
            color: #fff;
        }
    }
}