.dashboard {
    .loading-wrapper {
        background: rgba(177, 204, 180, 0.9);
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        @include breakpoint(md) {
            height: 100%;
            position: absolute;
        }
        z-index:999;
    }
    .loading-screen {
        position: absolute;
        top: 45%;
        left: 57.5%;
        transform: translate(-50%, -50%);
        display: block;
    }
}

.paddle{
  height:40px;
  width:3px;
  background-color:white;
  position:relative;
}

.player_one{
  @extend .paddle;
  left:-180px;
  animation: movePaddleOne 4s infinite;
}

.player_two{
  @extend .paddle;
  left:20px;
  animation: movePaddleTwo 4s infinite;
}

.ball{
  position:relative;
  height:5px;
  width:5px;
  border-radius:50%;
  background-color:white;
  animation: moveBall 4s infinite linear;
}

@keyframes movePaddleOne{
  0%, 100%{
    transform:translate(0px, 100px);
  }
  25%{
    transform:translate(0px, 0px);
  }
  50%{
    transform:translate(0px, 0px);
  }
  75%{
    transform:translate(0px, 100px);
  }
}

@keyframes movePaddleTwo{
  0%, 100%{
     transform:translate(0px,-50px);
  }
  25%{
       transform:translate(0px,10px);
  }
  50%{
        transform:translate(0px,0px);
  }
  75%{
        transform:translate(0px,50px);
  }
}

@keyframes moveBall{
   0%, 100%{
    transform:translate(-180px, 30px);
  }
  25%{
      transform:translate(18px, -25px);
  }
 50%{
     transform:translate(-180px, -55px);
  }
  75%{
    transform:translate(18px, 15px);
  }
}