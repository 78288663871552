// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.wrapper,
.container {
  max-width: $max-width; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 20px; /* 3 */
  padding-right: 20px; /* 3 */
  width: 100%; /* 1 */
  position: relative;
 }

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.text-center {
    text-align: center;
}

.txt-uppercase {
    text-transform: uppercase;
}

hr {
  background-color: $color-2;
  border: 0;
  height: 1px;
}

.hide {
    display: none;
}

.pt {
    padding-top: 40px;
}

.pst {
    padding-top: 20px;
}

.pb {
    padding-bottom: 40px;
}

.pl0 {
    padding-left: 0;
}

.pr0 {
    padding-right: 0;
}

.red {
    color: $red !important;
}

.display-block {
    display: block;
}

.show-only-on-small {
    display: block;
    @include breakpoint(md) {
        display: none;
    }
}

.img-gallery {
    img {
        margin: 10px;
    }
}

@include breakpoint(md) {
    .img-gallery {
        img {
            margin: 20px;
        }
    }
}

.py-4 {
    padding-top: 20px;
    @include breakpoint(md) {
        padding-top: 40px;
    }
}

.fit-cover {
    object-fit: cover;
}

.mt-100 {
  margin-top: 20px;
  @include breakpoint(md) {
        margin-top: 100px;
  }
}

.mt-130 {
  margin-top: 20px;
  @include breakpoint(md) {
    margin-top: 130px;
  }
}

.mt-50 {
  margin-top: 20px;
  @include breakpoint(md) {
    margin-top: 50px;
  }
}

@supports (grid-area: auto) {

  %grid-helpers-base {
    @include breakpoint(sm) {
      @include display-grid;
      grid-template-columns: 1fr;
    }
  }
}

.bl {
  border-left: 1px solid #ddd;
}

.br {
  border-right: 1px solid #ddd;
}

@media all and (min-width: 951px) {
  .ml {
    margin-left: 50px !important;
  }
}

@media all and (min-width: 1400px) {
  .ml {
    margin-left: 70px !important;
  }
}

img {
    &.align-center {
        display: flex;
        margin :auto;
    }
    &.align-right {
        float: right;
        margin: 0 0 15px 15px;
    }
    &.align-left {
        float: left;
        margin: 0 15px 15px 0;
    }
}