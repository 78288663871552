.header-nav {
  nav ul,
  nav li {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
  }

  nav a {
    display: block;
    text-decoration: none;
  }

  nav a:hover,
  nav a:visited {
    text-decoration: none;
  }
}

.menu-bar {
  display: flex;
}

.menu-link {
  padding: 20px 8px;
  background: none;
  color: $color-1;
  transition: background 0.2s, color 0.2s;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}

.mega-menu {
  .menu-link {
    padding: 5px;
  }
}

.menu-link[aria-haspopup="true"] {
  padding-right: 25px;
}

.menu-link[aria-haspopup="true"]:after {
  font-family: Linearicons-Free;
  content: "\e874";
  width: 14px;
  height: 14px;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.is-open {
  .menu-link[aria-haspopup="true"]:after {
    content: "\e873";
  }
}

.mega-menu-header {
  font-size: 1.1em;
  color: $color-1;
}

.mega-menu {
  background: #ffffff;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.016) 0 1.2px 1.9px -1px,
  rgba(0, 0, 0, 0.04) 0 3.3px 5.3px -1px,
  rgba(0, 0, 0, 0.086) 0 8.5px 12.7px -1px,
  rgba(0, 0, 0, 0.15) 0 30px 42px -1px;
}

.header-nav {
  border-bottom: 1px solid #ddd;

  .mobile-logo {
    display: inline-block;
  }

  .desktop-logo {
    display: none;
  }
}

nav {
  display: none;
}

@media all and (min-width: 951px) {
  .header-nav {
    top: 0;
    background: rgba(256, 256, 256, 0.9);
    position: fixed;
    width: 100%;
    z-index: 99;

    .mobile-logo {
      display: none;
      padding: 10px;
    }

    .desktop-logo {
      display: block;
    }

    .wrapper {
      margin: 0 auto;
      display: grid;
      grid-template-columns: 15% auto;
    }

    nav {
      display: grid;
      justify-items: end;
    }

    &:hover {
      background: rgba(256, 256, 256, 1);
    }
  }
  .cms-toolbar-expanded {
    .header-nav {
      top: 46px;
    }
  }

  .menu [aria-haspopup="true"] ~ ul {
    display: none;
  }
  .menu-bar {
    position: relative;
  }

  .menu-bar > li.is-open ul:not(.no-flex) {
    display: block;
    min-width: 100%;
    transform-origin: top;
    animation: dropdown 0.2s ease-out;
  }

  .menu-bar > li.is-open ul.no-flex {
    display: block;
    min-width: 100%;
    transform-origin: top;
    animation: dropdown 0.2s ease-out;
  }

  .menu-bar > li > [aria-haspopup="true"]:focus,
  .menu-bar > li:focus-within > [aria-haspopup="true"],
  .menu-bar > li:hover {
    background: $lightgray;
  }

  .menu-bar li:hover > a {
    text-decoration: underline;
  }

  .menu-bar > li:hover > a {
    text-decoration: none;
  }

  .mega-menu {
    position: absolute !important;
    top: 100%;
    left: 0;
    display: flex;
    padding-top: 10px !important;

    &.no-flex {
      display: block;
      padding-bottom: 10px;

      li {
        width: 100%;
        display: block;
        padding: 5px 15px 0;
        white-space: nowrap;
      }
    }
  }

  .mega-menu--flat:not(.no-flex) > * {
    flex: 1;
  }

  .header-nav nav .mega-menu--flat > li {
    white-space: nowrap;
    padding: 5px 15px 0;

    &:last-child {
      padding-bottom: 10px;
    }
  }

  .header-nav nav .menu-list {
    padding-bottom: 20px;
    display: block;

    li {
      padding: 0;
    }
  }

  .mobile-menu-trigger,
  .mobile-menu-header,
  .mobile-menu-back-item {
    display: none !important;
  }

  /* .menu-bar > li:focus-within > [aria-haspopup="true"] ~ ul {
    display: flex;
  }
  .menu-bar > li:focus-within > [aria-haspopup="true"] ~ ul.no-flex {
    display: block;
  } */

  .mega-menu-header {
      text-transform: none !important;
  }
}

.header-nav {
  nav {
    .lang {
      a {
        color: $color-1;
      }

      position: relative;
      z-index: 1;
      padding: 20px 5px;

      &.lang-active {
        a {
          color: #000;
          @include breakpoint(md) {
            color: $color-2 !important;
          }
        }
      }

      &:hover {
        background: none;

        a {
          background: none;
          color: $color-2;
        }
      }
    }

    .facebook-link {
      a {
        padding: 20px 5px;
      }
    }
  }
}

@media all and (max-width: 950px) {
  .is-mobile-menu-open {
    height: 100%;
    overflow: hidden;

    .header-nav {
      height: 100%;
      overflow: scroll;

      .wrapper, nav, {
        height: 100%;
      }
    }

    .menu-bar {
        position: absolute;
        margin: 0;
        padding: 0;
        left: 0;
        top: 70px;
        width: 100%;
        height: calc(100vh - 70px);
        @supports (-webkit-touch-callout: none) { /* ios fix */
            height: calc(100 * var(--vh) - 78px);
        }
        z-index: 999999;
        min-height: auto;
        max-height: none;
        overflow-y: auto;
    }
  }
  .header-nav {
    background: $color-2;
    position: fixed;
    z-index: 9999;
    width: 100%;

    .wrapper {
      width: 100%;
      padding: 0;
    }

    .mobile-logo {
      padding: 10px;
    }

    nav {
      border-top: 2px solid $color-1;
    }

    .menu-link {
        color: #fff;
        padding: 20px 20px 10px;

        &:hover,
        &:focus {
          color: $color-1;
        }
    }

    .mega-menu {
        background: #408871;
        padding: 0 20px 5px;
        li {
            padding: 5px 0;
            &:not(:last-child) {
                a {
                    border-bottom: 1px solid #35725f;
                }
            }
        }
    }
  }

  .mobile-menu-trigger,
  .mobile-menu-header,
  .mobile-menu-back-item {
    display: block;
  }

  .menu-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
  }
  .line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .mobile-menu-trigger {
    position: absolute;
    right: 0;
    top: 10px;
    color: #ffffff;
    border: 0;
    font-size: 1.2em;
  }

  .mobile-menu-header a {
    padding: 20px 25px;
    color: #ffffff;
    visibility: visible;
  }
  .menu-bar {
    display: block;
    position: fixed;
    top: 10px;
    height: 100%;
    width: 100%;
    transition: left 0.3s;
    z-index: 999;
    overflow: hidden;
  }

  .menu-bar > li > [aria-haspopup="true"] ~ ul > li > [aria-haspopup="true"] {
    font-size: 1.2em;
  }
  .menu-bar
  > li
  > [aria-haspopup="true"]
  ~ ul
  > li
  > [aria-haspopup="true"]
  ~ ul
  a {
    padding-left: 40px;
  }

  .menu-bar > li > [aria-haspopup="true"]:after {
    content: "+";
    background: none;
    font-size: 1em;
    font-weight: normal;
    height: 20px;
    line-height: 1;
  }
  .menu-item-has-children .mega-menu {
    display: none;
  }

  .menu-item-has-children.is-open .mega-menu {
    display: block;
  }

  .header-nav {
    .bl,
    .br {
      border: 0px solid transparent;
    }
    nav {
        .lang {
            width: 15%;
            float: left;
            padding: 20px;
        }
        .facebook-link {
            float: right;
            padding: 0 20px;
        }
    }
  }

  .menu-list {
    margin-bottom: 10px;
  }
  .mega-menu-link {
    color: #393939;
    font-weight: bold;
  }
}

@keyframes dropdown {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes flyout {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}
@media all and (min-width: 950px) {
 .menu-bar > li.is-open ul:not(.no-flex).menu-depth-3 {
        display: none;
        top: 0;
        background: white;
        padding: 5px;
        position: absolute;
        min-width: auto !important;
    }

.menu-bar > li.is-open ul:not(.no-flex).menu-depth-n {
        display: none;
        background: #fff;
        padding: 5px;
        position: absolute;
        top: 0;
        box-shadow: rgba(0,0,0,.016) 0 1.2px 1.9px -1px,rgba(0,0,0,.04) 0 3.3px 5.3px -1px,rgba(0,0,0,.086) 0 8.5px 12.7px -1px,rgba(0,0,0,.15) 0 30px 42px -1px;
        min-width: auto !important;
        &.show-hover {
            display: block !important;
        }
    }

    .show-hover {
        display: block !important;
    }

    .menu-depth-3 {
        box-shadow: rgba(0,0,0,.016) 0 1.2px 1.9px -1px,rgba(0,0,0,.04) 0 3.3px 5.3px -1px,rgba(0,0,0,.086) 0 8.5px 12.7px -1px,rgba(0,0,0,.15) 0 30px 42px -1px;
    }

    .menu-depth-3 li {
        padding: 10px !important;
    }
    .menu-item-has-gran-children::after {
        content: "\e876";
        font-family: Linearicons-Free;
        width: 14px;
        height: 14px;
        font-size: 12px;
        position: absolute;
        right: -5px;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media all and (min-width: 1350px) {
    .header-nav {
        .wrapper {
            grid-template-columns: 320px auto;
        }
    }

    .menu-link {
        padding: 20px 15px;
    }
}

@media all and (max-width: 950px) {
     .menu-depth-3 {
        left: 20px !important;
        min-width: 100% !important;
     }

     .menu-depth-n {
        left: 0px !important;
         min-width: 100% !important;
        li a {
            border-bottom: 1px solid #35725f;
        }
     }
}