@charset "UTF-8";
:root {
  --font-primary: system-ui, sans-serif;
  --text-base-size: 1em;
  --text-scale-ratio: 1.25;
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
  --body-line-height: 1.4;
  --heading-line-height: 1.2;
  --font-primary-capital-letter: 1;
}

:root {
  --heading-line-height: 1.2;
  --body-line-height: 1.4;
}

/* If we add the border using a regular CSS border, it won't look good on non-retina devices,
 * since its edges can look jagged due to lack of antialiasing. In this case, we are several
 * layers of box-shadow to add the border visually, which will render the border smoother. */
/* Fallback for non-latin fonts */
/* Calculates maximum width for post content */
/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-family: "Ubuntu", sans-serif;
  font-weight: normal;
  line-height: var(--body-line-height);
  margin: 0;
  text-rendering: optimizeLegibility;
}

.row {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 56.25em) {
  .row {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
img, iframe, video:not(.o-background_video) {
  max-width: 100%;
}

.page-content {
  padding: 80px 0;
}
@media only screen and (min-width: 56.25em) {
  .page-content {
    padding: 80px 0 150px;
  }
}

.facebook {
  filter: invert(0.4) sepia(0.5) saturate(15.1) hue-rotate(118.8deg) brightness(0.67);
}
.facebook:hover {
  filter: invert(0.4) sepia(0.5) saturate(15.1) hue-rotate(118.8deg) brightness(0.87);
}

#cms-top {
  z-index: 999 !important;
}

@font-face {
  font-family: "Linearicons-Free";
  src: url("/static/landing/fonts/Linearicons-Free.eot?w118d");
  src: url("/static/landing/fonts/Linearicons-Free.eot?#iefixw118d") format("embedded-opentype"), url("/static/landing/fonts/Linearicons-Free.woff2?w118d") format("woff2"), url("/static/landing/fonts/Linearicons-Free.woff?w118d") format("woff"), url("/static/landing/fonts/Linearicons-Free.ttf?w118d") format("truetype"), url("/static/landing/fonts/Linearicons-Free.svg?w118d#Linearicons-Free") format("svg");
  font-weight: normal;
  font-style: normal;
}
.lnr {
  font-family: "Linearicons-Free";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lnr-home:before {
  content: "\e800";
}

.lnr-apartment:before {
  content: "\e801";
}

.lnr-pencil:before {
  content: "\e802";
}

.lnr-magic-wand:before {
  content: "\e803";
}

.lnr-drop:before {
  content: "\e804";
}

.lnr-lighter:before {
  content: "\e805";
}

.lnr-poop:before {
  content: "\e806";
}

.lnr-sun:before {
  content: "\e807";
}

.lnr-moon:before {
  content: "\e808";
}

.lnr-cloud:before {
  content: "\e809";
}

.lnr-cloud-upload:before {
  content: "\e80a";
}

.lnr-cloud-download:before {
  content: "\e80b";
}

.lnr-cloud-sync:before {
  content: "\e80c";
}

.lnr-cloud-check:before {
  content: "\e80d";
}

.lnr-database:before {
  content: "\e80e";
}

.lnr-lock:before {
  content: "\e80f";
}

.lnr-cog:before {
  content: "\e810";
}

.lnr-trash:before {
  content: "\e811";
}

.lnr-dice:before {
  content: "\e812";
}

.lnr-heart:before {
  content: "\e813";
}

.lnr-star:before {
  content: "\e814";
}

.lnr-star-half:before {
  content: "\e815";
}

.lnr-star-empty:before {
  content: "\e816";
}

.lnr-flag:before {
  content: "\e817";
}

.lnr-envelope:before {
  content: "\e818";
}

.lnr-paperclip:before {
  content: "\e819";
}

.lnr-inbox:before {
  content: "\e81a";
}

.lnr-eye:before {
  content: "\e81b";
}

.lnr-printer:before {
  content: "\e81c";
}

.lnr-file-empty:before {
  content: "\e81d";
}

.lnr-file-add:before {
  content: "\e81e";
}

.lnr-enter:before {
  content: "\e81f";
}

.lnr-exit:before {
  content: "\e820";
}

.lnr-graduation-hat:before {
  content: "\e821";
}

.lnr-license:before {
  content: "\e822";
}

.lnr-music-note:before {
  content: "\e823";
}

.lnr-film-play:before {
  content: "\e824";
}

.lnr-camera-video:before {
  content: "\e825";
}

.lnr-camera:before {
  content: "\e826";
}

.lnr-picture:before {
  content: "\e827";
}

.lnr-book:before {
  content: "\e828";
}

.lnr-bookmark:before {
  content: "\e829";
}

.lnr-user:before {
  content: "\e82a";
}

.lnr-users:before {
  content: "\e82b";
}

.lnr-shirt:before {
  content: "\e82c";
}

.lnr-store:before {
  content: "\e82d";
}

.lnr-cart:before {
  content: "\e82e";
}

.lnr-tag:before {
  content: "\e82f";
}

.lnr-phone-handset:before {
  content: "\e830";
}

.lnr-phone:before {
  content: "\e831";
}

.lnr-pushpin:before {
  content: "\e832";
}

.lnr-map-marker:before {
  content: "\e833";
}

.lnr-map:before {
  content: "\e834";
}

.lnr-location:before {
  content: "\e835";
}

.lnr-calendar-full:before {
  content: "\e836";
}

.lnr-keyboard:before {
  content: "\e837";
}

.lnr-spell-check:before {
  content: "\e838";
}

.lnr-screen:before {
  content: "\e839";
}

.lnr-smartphone:before {
  content: "\e83a";
}

.lnr-tablet:before {
  content: "\e83b";
}

.lnr-laptop:before {
  content: "\e83c";
}

.lnr-laptop-phone:before {
  content: "\e83d";
}

.lnr-power-switch:before {
  content: "\e83e";
}

.lnr-bubble:before {
  content: "\e83f";
}

.lnr-heart-pulse:before {
  content: "\e840";
}

.lnr-construction:before {
  content: "\e841";
}

.lnr-pie-chart:before {
  content: "\e842";
}

.lnr-chart-bars:before {
  content: "\e843";
}

.lnr-gift:before {
  content: "\e844";
}

.lnr-diamond:before {
  content: "\e845";
}

.lnr-linearicons:before {
  content: "\e846";
}

.lnr-dinner:before {
  content: "\e847";
}

.lnr-coffee-cup:before {
  content: "\e848";
}

.lnr-leaf:before {
  content: "\e849";
}

.lnr-paw:before {
  content: "\e84a";
}

.lnr-rocket:before {
  content: "\e84b";
}

.lnr-briefcase:before {
  content: "\e84c";
}

.lnr-bus:before {
  content: "\e84d";
}

.lnr-car:before {
  content: "\e84e";
}

.lnr-train:before {
  content: "\e84f";
}

.lnr-bicycle:before {
  content: "\e850";
}

.lnr-wheelchair:before {
  content: "\e851";
}

.lnr-select:before {
  content: "\e852";
}

.lnr-earth:before {
  content: "\e853";
}

.lnr-smile:before {
  content: "\e854";
}

.lnr-sad:before {
  content: "\e855";
}

.lnr-neutral:before {
  content: "\e856";
}

.lnr-mustache:before {
  content: "\e857";
}

.lnr-alarm:before {
  content: "\e858";
}

.lnr-bullhorn:before {
  content: "\e859";
}

.lnr-volume-high:before {
  content: "\e85a";
}

.lnr-volume-medium:before {
  content: "\e85b";
}

.lnr-volume-low:before {
  content: "\e85c";
}

.lnr-volume:before {
  content: "\e85d";
}

.lnr-mic:before {
  content: "\e85e";
}

.lnr-hourglass:before {
  content: "\e85f";
}

.lnr-undo:before {
  content: "\e860";
}

.lnr-redo:before {
  content: "\e861";
}

.lnr-sync:before {
  content: "\e862";
}

.lnr-history:before {
  content: "\e863";
}

.lnr-clock:before {
  content: "\e864";
}

.lnr-download:before {
  content: "\e865";
}

.lnr-upload:before {
  content: "\e866";
}

.lnr-enter-down:before {
  content: "\e867";
}

.lnr-exit-up:before {
  content: "\e868";
}

.lnr-bug:before {
  content: "\e869";
}

.lnr-code:before {
  content: "\e86a";
}

.lnr-link:before {
  content: "\e86b";
}

.lnr-unlink:before {
  content: "\e86c";
}

.lnr-thumbs-up:before {
  content: "\e86d";
}

.lnr-thumbs-down:before {
  content: "\e86e";
}

.lnr-magnifier:before {
  content: "\e86f";
}

.lnr-cross:before {
  content: "\e870";
}

.lnr-menu:before {
  content: "\e871";
}

.lnr-list:before {
  content: "\e872";
}

.lnr-chevron-up:before {
  content: "\e873";
}

.lnr-chevron-down:before {
  content: "\e874";
}

.lnr-chevron-left:before {
  content: "\e875";
}

.lnr-chevron-right:before {
  content: "\e876";
}

.lnr-arrow-up:before {
  content: "\e877";
}

.lnr-arrow-down:before {
  content: "\e878";
}

.lnr-arrow-left:before {
  content: "\e879";
}

.lnr-arrow-right:before {
  content: "\e87a";
}

.lnr-move:before {
  content: "\e87b";
}

.lnr-warning:before {
  content: "\e87c";
}

.lnr-warning {
  color: #e2552e;
}

.lnr-question-circle:before {
  content: "\e87d";
}

.lnr-menu-circle:before {
  content: "\e87e";
}

.lnr-checkmark-circle:before {
  content: "\e87f";
}

.lnr-cross-circle:before {
  content: "\e880";
}

.lnr-plus-circle:before {
  content: "\e881";
}

.lnr-circle-minus:before {
  content: "\e882";
}

.lnr-arrow-up-circle:before {
  content: "\e883";
}

.lnr-arrow-down-circle:before {
  content: "\e884";
}

.lnr-arrow-left-circle:before {
  content: "\e885";
}

.lnr-arrow-right-circle:before {
  content: "\e886";
}

.lnr-chevron-up-circle:before {
  content: "\e887";
}

.lnr-chevron-down-circle:before {
  content: "\e888";
}

.lnr-chevron-left-circle:before {
  content: "\e889";
}

.lnr-chevron-right-circle:before {
  content: "\e88a";
}

.lnr-crop:before {
  content: "\e88b";
}

.lnr-frame-expand:before {
  content: "\e88c";
}

.lnr-frame-contract:before {
  content: "\e88d";
}

.lnr-layers:before {
  content: "\e88e";
}

.lnr-funnel:before {
  content: "\e88f";
}

.lnr-text-format:before {
  content: "\e890";
}

.lnr-text-format-remove:before {
  content: "\e891";
}

.lnr-text-size:before {
  content: "\e892";
}

.lnr-bold:before {
  content: "\e893";
}

.lnr-italic:before {
  content: "\e894";
}

.lnr-underline:before {
  content: "\e895";
}

.lnr-strikethrough:before {
  content: "\e896";
}

.lnr-highlight:before {
  content: "\e897";
}

.lnr-text-align-left:before {
  content: "\e898";
}

.lnr-text-align-center:before {
  content: "\e899";
}

.lnr-text-align-right:before {
  content: "\e89a";
}

.lnr-text-align-justify:before {
  content: "\e89b";
}

.lnr-line-spacing:before {
  content: "\e89c";
}

.lnr-indent-increase:before {
  content: "\e89d";
}

.lnr-indent-decrease:before {
  content: "\e89e";
}

.lnr-pilcrow:before {
  content: "\e89f";
}

.lnr-direction-ltr:before {
  content: "\e8a0";
}

.lnr-direction-rtl:before {
  content: "\e8a1";
}

.lnr-page-break:before {
  content: "\e8a2";
}

.lnr-sort-alpha-asc:before {
  content: "\e8a3";
}

.lnr-sort-amount-asc:before {
  content: "\e8a4";
}

.lnr-hand:before {
  content: "\e8a5";
}

.lnr-pointer-up:before {
  content: "\e8a6";
}

.lnr-pointer-right:before {
  content: "\e8a7";
}

.lnr-pointer-down:before {
  content: "\e8a8";
}

.lnr-pointer-left:before {
  content: "\e8a9";
}

/* pt-sans-regular - latin-ext_latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("PT Sans"), local("PTSans-Regular"), url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-regular.woff2") format("woff2"), url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-regular.woff") format("woff"), url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-regular.ttf") format("truetype"), url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-regular.svg#PTSans") format("svg");
  /* Legacy iOS */
}
/* pt-sans-700 - latin-ext_latin */
@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-700.eot");
  /* IE9 Compat Modes */
  src: local("PT Sans Bold"), local("PTSans-Bold"), url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-700.woff2") format("woff2"), url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-700.woff") format("woff"), url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-700.ttf") format("truetype"), url("/static/landing/fonts/pt-sans/pt-sans-v12-latin-ext_latin-700.svg#PTSans") format("svg");
  /* Legacy iOS */
}
/**
 * Basic styles for links
 */
a {
  color: #377561;
  text-decoration: none;
}
a:hover {
  color: #275244;
  text-decoration: underline;
}
a:hover, a:focus, a:active {
  outline: none;
  color: #377561;
}
a:focus, a:active {
  text-decoration: none !important;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: -apple-system, BlinkMacSystemFont, "PT Sans", "Poppins", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 0.67em;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  line-height: var(--heading-line-height, 1.2);
}

h1, .h1 {
  font-size: var(--text-xxl, 2.074em);
  font-weight: bold;
  padding-bottom: 35px;
}

h2, .h2 {
  font-size: var(--text-lg, 1.44em);
}
@media only screen and (min-width: 56.25em) {
  h2, .h2 {
    font-size: var(--text-xl, 1.528em);
  }
}

h3, .h3 {
  font-size: var(--text-lg, 1.44em);
}

h4, .h4 {
  font-size: var(--text-md, 1.2em);
}

h5, .h5 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

small {
  font-size: var(--text-xs, 0.833em);
}

.blog-content p a,
.read-more,
.text-link:not(.btn-block) {
  outline: none;
  position: relative;
  text-decoration: none;
  z-index: 1;
  color: #000;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0, #55c1aa), to(#55c1aa));
  background-image: -webkit-linear-gradient(left, #55c1aa 0, #55c1aa 100%);
  background-image: -o-linear-gradient(left, #55c1aa 0, #55c1aa 100%);
  background-image: linear-gradient(to right, #55c1aa 0, #55c1aa 100%);
  background-repeat: no-repeat;
  background-size: 100% 2px;
  background-position: 0 100%;
  -webkit-transition: background-size 0.25s ease-in;
  -o-transition: background-size 0.25s ease-in;
  transition: background-size 0.25s ease-in;
}
.blog-content p a:hover,
.read-more:hover,
.text-link:not(.btn-block):hover {
  color: #000;
  text-decoration: none;
  background-size: 100% 2em;
}

.text-link.btn-block-styled {
  padding: 10px 75px 10px 20px !important;
  border: 0 none;
  box-shadow: 0 1px 4px rgba(146, 157, 162, 0.5);
  transform-origin: left;
  transition: background 1s ease;
  border-radius: 6px;
  position: relative;
  color: #333;
  margin-bottom: 11px;
}
.text-link.btn-block-styled:after {
  background: #377561;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  content: "➜";
  color: white;
  line-height: 100%;
  transform-origin: left center;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  transition: width 0.3s ease 0s;
  border-radius: 0px 6px 6px 0px;
}
.text-link.btn-block-styled:hover {
  box-shadow: 0 1px 10px rgba(146, 157, 162, 0.5);
  color: #377561;
  text-decoration: none;
}
.text-link.btn-block-styled:hover:after {
  width: 65px;
}

.dark-theme .read-more {
  text-decoration: none;
  background-size: 100% 2em;
  color: #2d2d2d !important;
}
.dark-theme .read-more:hover {
  color: #fff !important;
  background-size: 100% 0.2em;
}

.text-link.btn-block {
  padding: 10px 75px 10px 20px !important;
  margin-bottom: 11px;
  position: relative;
  letter-spacing: 1px;
}
.text-link.btn-block.active:after, .text-link.btn-block:hover:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 0.25rem;
  background: #377561;
}

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.wrapper,
.container {
  max-width: 1540px;
  /* 1 */
  margin-left: auto;
  /* 2 */
  margin-right: auto;
  /* 2 */
  padding-left: 20px;
  /* 3 */
  padding-right: 20px;
  /* 3 */
  width: 100%;
  /* 1 */
  position: relative;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.text-center {
  text-align: center;
}

.txt-uppercase {
  text-transform: uppercase;
}

hr {
  background-color: #377561;
  border: 0;
  height: 1px;
}

.hide {
  display: none;
}

.pt {
  padding-top: 40px;
}

.pst {
  padding-top: 20px;
}

.pb {
  padding-bottom: 40px;
}

.pl0 {
  padding-left: 0;
}

.pr0 {
  padding-right: 0;
}

.red {
  color: #e2552e !important;
}

.display-block {
  display: block;
}

.show-only-on-small {
  display: block;
}
@media only screen and (min-width: 56.25em) {
  .show-only-on-small {
    display: none;
  }
}

.img-gallery img {
  margin: 10px;
}

@media only screen and (min-width: 56.25em) {
  .img-gallery img {
    margin: 20px;
  }
}
.py-4 {
  padding-top: 20px;
}
@media only screen and (min-width: 56.25em) {
  .py-4 {
    padding-top: 40px;
  }
}

.fit-cover {
  object-fit: cover;
}

.mt-100 {
  margin-top: 20px;
}
@media only screen and (min-width: 56.25em) {
  .mt-100 {
    margin-top: 100px;
  }
}

.mt-130 {
  margin-top: 20px;
}
@media only screen and (min-width: 56.25em) {
  .mt-130 {
    margin-top: 130px;
  }
}

.mt-50 {
  margin-top: 20px;
}
@media only screen and (min-width: 56.25em) {
  .mt-50 {
    margin-top: 50px;
  }
}

@supports (grid-area: auto) {
  @media only screen and (min-width: 37.5em) {
    .document .card-body, .cols-document, .cols-4-order-list, .cols-with-cart, .cols-sidebar-right, .cols-small-sidebar-left, .cols-sidebar-left, .cols-2-news, .cols-auto-fit,
.cols-3-gallery, .resources .cols-4, .cols-4, .cols-5, .cols-3-nogap, .cols-3, .cols-2-intro, .cols-2 {
      display: grid;
      grid-gap: 2rem;
      grid-template-rows: auto;
      grid-template-columns: 1fr;
    }
  }
}
.bl {
  border-left: 1px solid #ddd;
}

.br {
  border-right: 1px solid #ddd;
}

@media all and (min-width: 951px) {
  .ml {
    margin-left: 50px !important;
  }
}
@media all and (min-width: 1400px) {
  .ml {
    margin-left: 70px !important;
  }
}
img.align-center {
  display: flex;
  margin: auto;
}
img.align-right {
  float: right;
  margin: 0 0 15px 15px;
}
img.align-left {
  float: left;
  margin: 0 15px 15px 0;
}

.listings {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
.listings li {
  border-bottom: 1px solid #f1f1f1;
  padding: 15px 0;
}

table {
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
table th {
  font-weight: bold;
  vertical-align: bottom;
}
table td {
  vertical-align: top;
}
table th, table td {
  padding: 0.47059em;
  text-align: left;
  border-top: 0.07143em solid #b8b8b8;
}
table.order-table td {
  padding: 20px !important;
}

table thead:first-child tr th, table thead:first-child tr td {
  border-top: 0;
}

@supports (grid-area: auto) {
  @media only screen and (min-width: 56.25em) {
    .cols-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (min-width: 56.25em) {
    .cols-2-intro {
      grid-template-columns: 120px auto;
    }
  }

  @media only screen and (min-width: 56.25em) {
    .cols-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .cols-3-nogap.intro-columns {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0;
  }
  @media only screen and (min-width: 56.25em) {
    .cols-3-nogap:not(.intro-columns) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 0;
    }
  }

  @media only screen and (min-width: 56.25em) {
    .cols-5 {
      grid-template-columns: repeat(5, 1fr);
      grid-row-gap: 2rem;
    }
  }

  @media only screen and (min-width: 56.25em) {
    .cols-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media only screen and (min-width: 56.25em) {
    .resources .cols-4 {
      grid-template-columns: repeat(4, calc(25% - 40px));
    }
  }

  @media only screen and (min-width: 56.25em) {
    .cols-auto-fit,
.cols-3-gallery {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-gap: 1rem;
    }
  }

  @media only screen and (min-width: 56.25em) {
    .cols-2-news {
      grid-template-columns: 250px auto;
      grid-gap: 1rem;
    }
  }

  @media only screen and (min-width: 56.25em) {
    .home .cols-2-news {
      grid-template-columns: 120px auto;
    }
  }

  .cols-sidebar-left {
    display: grid;
  }
  @media screen and (max-width: 900px) {
    .cols-sidebar-left div:first-child {
      order: 2;
    }
  }
  @media only screen and (min-width: 56.25em) {
    .cols-sidebar-left {
      grid-template-columns: 350px auto;
    }
  }

  .cols-small-sidebar-left {
    display: grid;
  }
  @media screen and (max-width: 1200px) {
    .cols-small-sidebar-left div.sidebar {
      order: 2;
    }
  }
  @media only screen and (min-width: 37.5em) {
    .cols-small-sidebar-left {
      grid-template-columns: 235px auto;
    }
  }

  @media only screen and (min-width: 56.25em) {
    .cols-sidebar-right {
      grid-template-columns: auto 350px;
    }
  }

  @media only screen and (min-width: 56.25em) {
    .cols-with-cart {
      grid-template-columns: auto 250px;
    }
  }
  @media screen and (max-width: 900px) {
    .cols-with-cart {
      display: grid;
    }
    .cols-with-cart div.cart {
      order: 1;
    }
    .cols-with-cart div.order-listing {
      order: 2;
    }
  }

  .cols-4-order-list {
    grid-gap: 0;
  }
  @media only screen and (min-width: 56.25em) {
    .cols-4-order-list {
      grid-template-columns: 1fr 7fr 2fr 2fr;
    }
  }

  .cols-document {
    grid-template-columns: 9fr 3fr;
  }

  .document .card-body {
    grid-template-columns: auto 250px;
  }
}
@supports not (grid-area: auto) {
  .cols-2,
.cols-3,
.cols-4,
.cols-auto-fit,
.cols-sidebar-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    clear: both;
  }
  .cols-2 > div,
.cols-3 > div,
.cols-4 > div,
.cols-auto-fit > div,
.cols-sidebar-right > div {
    flex: 1;
    padding: 16px;
  }
}
input {
  border-radius: 0;
}

label {
  color: #377561;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
  font-weight: normal;
  width: 100%;
  line-height: 1rem;
  padding-top: 0.3em;
}

.ksw-form {
  opacity: 1;
  top: 20px;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.25, 0.265, 0.85);
  transition-property: transform, opacity, box-shadow, top, left;
  transition-duration: 0.5s;
  transform-origin: 161px 100%;
  transform: rotateX(0deg);
  position: relative;
  max-width: 400px;
  border-top: 2px solid #377561;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  padding: 50px 0;
  background: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.ksw-form input[type=password] {
  color: #377561 !important;
}
.ksw-form input[type=text],
.ksw-form input[type=password],
.ksw-form input[type=email],
.ksw-form input[type=number],
.ksw-form select,
.ksw-form textarea {
  color: #000;
  width: 100%;
  margin-top: -2px;
  background: transparent;
  left: 0;
  padding: 10px 40px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-right: none;
  border-left: none;
  outline: none;
  box-shadow: none;
}
.ksw-form input {
  background: transparent;
  transition-property: background, color;
  transition-duration: 0.2s;
}
.ksw-form input:focus {
  background: #fff;
  box-shadow: none;
  outline: none;
}
.ksw-form input:hover {
  transition-property: background, color;
  transition-duration: 0.2s;
}
.ksw-form label {
  padding-left: 40px;
}
.ksw-form .secondaryAction {
  border: none;
  padding: 0;
  text-transform: none;
}
.ksw-form .ksw-form-btn-wrap {
  margin: 20px 0;
}
.ksw-form.edit-mode input[type=text],
.ksw-form.edit-mode input[type=password],
.ksw-form.edit-mode input[type=email],
.ksw-form.edit-mode input[type=number],
.ksw-form.edit-mode select,
.ksw-form.edit-mode textarea {
  background: #f5f8fc;
}

.ksw-form-index .ksw-form-btn-wrap {
  margin: 40px 0 10px;
}

.side-content .ksw-form {
  margin: auto 0;
  height: 100%;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #222;
  text-transform: none;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #222;
  text-transform: none;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #222;
  text-transform: none;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #222;
  text-transform: none;
}

label[for=id_remember] {
  width: auto;
}

input[type=text],
input[type=email],
input[type=url],
input[type=password],
input[type=search],
input[type=number],
input[type=tel],
input[type=range],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=color],
textarea,
select {
  box-shadow: none;
  width: 100%;
  border: 1px solid #377561;
  line-height: 1.4;
  padding: 7px;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=number]:focus,
input[type=tel]:focus,
input[type=range]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=week]:focus,
input[type=time]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=color]:focus,
textarea:focus,
select:focus {
  border-color: rgba(55, 117, 97, 0.9);
  color: #111;
  outline: 0;
  outline-offset: -4px;
}

textarea {
  box-sizing: border-box;
  display: block;
  height: 150px;
  width: 100%;
  max-width: 100%;
  resize: vertical;
}
textarea.other {
  height: 50px;
}

.submit-btn-wrap {
  margin-top: 30px;
}

.poll-form {
  background: #fff;
  padding: 40px;
}
.poll-form ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
}
.poll-form ul li {
  color: #AAAAAA;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #ddd;
}
.poll-form ul li input[type=radio] {
  position: absolute;
  visibility: hidden;
}
.poll-form ul li label {
  color: #aaa;
  display: block;
  position: relative;
  padding: 25px 25px 25px 80px;
  margin: 10px auto;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}
.poll-form ul li:hover label {
  color: #377561;
}
.poll-form ul li .check {
  display: block;
  position: absolute;
  border: 5px solid #AAAAAA;
  border-radius: 100%;
  height: 26px;
  width: 26px;
  top: 30px;
  left: 20px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}
.poll-form ul li:hover .check {
  border: 5px solid #377561;
}
.poll-form ul li .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 12px;
  width: 12px;
  top: 2px;
  left: 2px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}
.poll-form input[type=radio]:checked ~ .check {
  border: 5px solid #55c1aa;
}
.poll-form input[type=radio]:checked ~ .check::before {
  background: #55c1aa;
}
.poll-form input[type=radio]:checked ~ label {
  color: #55c1aa;
}
.poll-form input[type=submit] {
  margin-top: 25px;
  position: relative;
  z-index: 9999;
}

.errorlist {
  list-style-type: none;
}
.errorlist li {
  color: #e2552e;
}

label[for=id_form-0-DELETE],
label[for=id_form-1-DELETE],
label[for=id_form-2-DELETE],
label[for=id_form-3-DELETE],
label[for=id_form-4-DELETE],
label[for=id_form-5-DELETE] {
  width: auto;
}

.ksw-form-address input[type=checkbox] {
  position: relative;
  top: 3px;
  left: 10px;
}

#id_index, #id_water_meter {
  width: 98%;
  border: 1px solid #ddd !important;
}

select {
  background-color: transparent;
}

#signup_form {
  padding-bottom: 120px;
}
#signup_form #id_hcaptcha {
  position: absolute;
  bottom: 100px;
  text-align: center;
  width: 100%;
}
#signup_form .submit-btn-wrap {
  position: relative;
  top: 80px;
  z-index: 9999;
}

.fieldErr {
  border: 1px solid red !important;
}

.alert-danger {
  text-align: center;
  max-width: 400px;
  margin: auto;
}

@media all and (min-width: 951px) {
  .site-logo img {
    position: absolute;
    width: 18%;
  }
}
@media all and (min-width: 1350px) {
  .site-logo img {
    max-width: 320px;
  }
}
#footer {
  background-color: #192d2a;
  color: #c4c4c4;
  padding: 20px 0;
  position: relative;
}
@media only screen and (min-width: 56.25em) {
  #footer {
    padding: 100px 0;
  }
}
#footer .nav ul {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}
#footer .nav ul li {
  list-style-type: none;
}
#footer .nav ul li.current_page_item a,
#footer .nav ul li a:hover {
  color: #377561;
}
#footer .nav ul li a {
  color: #fff;
  text-transform: uppercase;
  line-height: 1.1;
  position: relative;
  display: block;
  padding: 10px 15px;
}
#footer .nav ul li a:hover {
  background-color: #f1f1f1;
  color: #377561;
  text-decoration: underline;
}
#footer .contact-lines strong {
  text-decoration: underline;
}
#footer .contact-lines a:not(.social) {
  line-height: 1.7;
  overflow: hidden;
  color: #377561;
  padding: 1px 2px 0;
  border-bottom: 3px solid #fff;
}
#footer .contact-lines a:not(.social):hover {
  color: #377561;
  border-bottom: 3px solid #fcba06;
  background: none !important;
}
#footer .contact-line-title {
  letter-spacing: 1px;
  padding-bottom: 8px;
  text-transform: uppercase;
}
#footer h5 {
  line-height: 1.6;
}
#footer .disclaimer {
  border-top: 1px solid #515151;
  padding-top: 40px;
}
#footer .disclaimer a {
  color: #55c1aa;
  line-height: 1.6;
}
#footer .disclaimer img {
  width: 200px;
}
#footer .social-links {
  margin: 30px 0;
}
#footer .social-links .social {
  color: #377561;
  opacity: 1;
  line-height: 30px;
  margin: 0px 10px 10px 0px;
  text-align: center;
}
#footer .social-links .social:hover {
  opacity: 0.8;
}

.dashboard #footer {
  background: #2d2d2d;
}

.body-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100%;
  top: 0;
  display: none;
}

.with-overlay {
  overflow: hidden;
}
.with-overlay .body-overlay {
  display: block;
}

.footer-img {
  position: absolute;
  top: -70px;
  filter: invert(0.4) sepia(0.5) saturate(2.1) hue-rotate(118.8deg) brightness(0.67);
}
@media only screen and (min-width: 56.25em) {
  .footer-img {
    top: -120px;
  }
}
@media only screen and (min-width: 112em) {
  .footer-img {
    top: -180px;
  }
}

.dashboard .footer-img {
  display: none;
}

.header-nav nav ul,
.header-nav nav li {
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}
.header-nav nav a {
  display: block;
  text-decoration: none;
}
.header-nav nav a:hover,
.header-nav nav a:visited {
  text-decoration: none;
}

.menu-bar {
  display: flex;
}

.menu-link {
  padding: 20px 8px;
  background: none;
  color: #333;
  transition: background 0.2s, color 0.2s;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}

.mega-menu .menu-link {
  padding: 5px;
}

.menu-link[aria-haspopup=true] {
  padding-right: 25px;
}

.menu-link[aria-haspopup=true]:after {
  font-family: Linearicons-Free;
  content: "\e874";
  width: 14px;
  height: 14px;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.is-open .menu-link[aria-haspopup=true]:after {
  content: "\e873";
}

.mega-menu-header {
  font-size: 1.1em;
  color: #333;
}

.mega-menu {
  background: #ffffff;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.016) 0 1.2px 1.9px -1px, rgba(0, 0, 0, 0.04) 0 3.3px 5.3px -1px, rgba(0, 0, 0, 0.086) 0 8.5px 12.7px -1px, rgba(0, 0, 0, 0.15) 0 30px 42px -1px;
}

.header-nav {
  border-bottom: 1px solid #ddd;
}
.header-nav .mobile-logo {
  display: inline-block;
}
.header-nav .desktop-logo {
  display: none;
}

nav {
  display: none;
}

@media all and (min-width: 951px) {
  .header-nav {
    top: 0;
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    width: 100%;
    z-index: 99;
  }
  .header-nav .mobile-logo {
    display: none;
    padding: 10px;
  }
  .header-nav .desktop-logo {
    display: block;
  }
  .header-nav .wrapper {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 15% auto;
  }
  .header-nav nav {
    display: grid;
    justify-items: end;
  }
  .header-nav:hover {
    background: white;
  }

  .cms-toolbar-expanded .header-nav {
    top: 46px;
  }

  .menu [aria-haspopup=true] ~ ul {
    display: none;
  }

  .menu-bar {
    position: relative;
  }

  .menu-bar > li.is-open ul:not(.no-flex) {
    display: block;
    min-width: 100%;
    transform-origin: top;
    animation: dropdown 0.2s ease-out;
  }

  .menu-bar > li.is-open ul.no-flex {
    display: block;
    min-width: 100%;
    transform-origin: top;
    animation: dropdown 0.2s ease-out;
  }

  .menu-bar > li > [aria-haspopup=true]:focus,
.menu-bar > li:focus-within > [aria-haspopup=true],
.menu-bar > li:hover {
    background: #f1f1f1;
  }

  .menu-bar li:hover > a {
    text-decoration: underline;
  }

  .menu-bar > li:hover > a {
    text-decoration: none;
  }

  .mega-menu {
    position: absolute !important;
    top: 100%;
    left: 0;
    display: flex;
    padding-top: 10px !important;
  }
  .mega-menu.no-flex {
    display: block;
    padding-bottom: 10px;
  }
  .mega-menu.no-flex li {
    width: 100%;
    display: block;
    padding: 5px 15px 0;
    white-space: nowrap;
  }

  .mega-menu--flat:not(.no-flex) > * {
    flex: 1;
  }

  .header-nav nav .mega-menu--flat > li {
    white-space: nowrap;
    padding: 5px 15px 0;
  }
  .header-nav nav .mega-menu--flat > li:last-child {
    padding-bottom: 10px;
  }

  .header-nav nav .menu-list {
    padding-bottom: 20px;
    display: block;
  }
  .header-nav nav .menu-list li {
    padding: 0;
  }

  .mobile-menu-trigger,
.mobile-menu-header,
.mobile-menu-back-item {
    display: none !important;
  }

  /* .menu-bar > li:focus-within > [aria-haspopup="true"] ~ ul {
    display: flex;
  }
  .menu-bar > li:focus-within > [aria-haspopup="true"] ~ ul.no-flex {
    display: block;
  } */
  .mega-menu-header {
    text-transform: none !important;
  }
}
.header-nav nav .lang {
  position: relative;
  z-index: 1;
  padding: 20px 5px;
}
.header-nav nav .lang a {
  color: #333;
}
.header-nav nav .lang.lang-active a {
  color: #000;
}
@media only screen and (min-width: 56.25em) {
  .header-nav nav .lang.lang-active a {
    color: #377561 !important;
  }
}
.header-nav nav .lang:hover {
  background: none;
}
.header-nav nav .lang:hover a {
  background: none;
  color: #377561;
}
.header-nav nav .facebook-link a {
  padding: 20px 5px;
}

@media all and (max-width: 950px) {
  .is-mobile-menu-open {
    height: 100%;
    overflow: hidden;
  }
  .is-mobile-menu-open .header-nav {
    height: 100%;
    overflow: scroll;
  }
  .is-mobile-menu-open .header-nav .wrapper, .is-mobile-menu-open .header-nav nav {
    height: 100%;
  }
  .is-mobile-menu-open .menu-bar {
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: 70px;
    width: 100%;
    height: calc(100vh - 70px);
    z-index: 999999;
    min-height: auto;
    max-height: none;
    overflow-y: auto;
  }
  @supports (-webkit-touch-callout: none) {
    .is-mobile-menu-open .menu-bar {
      /* ios fix */
      height: calc(100 * var(--vh) - 78px);
    }
  }

  .header-nav {
    background: #377561;
    position: fixed;
    z-index: 9999;
    width: 100%;
  }
  .header-nav .wrapper {
    width: 100%;
    padding: 0;
  }
  .header-nav .mobile-logo {
    padding: 10px;
  }
  .header-nav nav {
    border-top: 2px solid #333;
  }
  .header-nav .menu-link {
    color: #fff;
    padding: 20px 20px 10px;
  }
  .header-nav .menu-link:hover, .header-nav .menu-link:focus {
    color: #333;
  }
  .header-nav .mega-menu {
    background: #408871;
    padding: 0 20px 5px;
  }
  .header-nav .mega-menu li {
    padding: 5px 0;
  }
  .header-nav .mega-menu li:not(:last-child) a {
    border-bottom: 1px solid #35725f;
  }

  .mobile-menu-trigger,
.mobile-menu-header,
.mobile-menu-back-item {
    display: block;
  }

  .menu-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
  }

  .line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }

  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }

  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }

  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }

  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }

  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }

  .mobile-menu-trigger {
    position: absolute;
    right: 0;
    top: 10px;
    color: #ffffff;
    border: 0;
    font-size: 1.2em;
  }

  .mobile-menu-header a {
    padding: 20px 25px;
    color: #ffffff;
    visibility: visible;
  }

  .menu-bar {
    display: block;
    position: fixed;
    top: 10px;
    height: 100%;
    width: 100%;
    transition: left 0.3s;
    z-index: 999;
    overflow: hidden;
  }

  .menu-bar > li > [aria-haspopup=true] ~ ul > li > [aria-haspopup=true] {
    font-size: 1.2em;
  }

  .menu-bar > li > [aria-haspopup=true] ~ ul > li > [aria-haspopup=true] ~ ul a {
    padding-left: 40px;
  }

  .menu-bar > li > [aria-haspopup=true]:after {
    content: "+";
    background: none;
    font-size: 1em;
    font-weight: normal;
    height: 20px;
    line-height: 1;
  }

  .menu-item-has-children .mega-menu {
    display: none;
  }

  .menu-item-has-children.is-open .mega-menu {
    display: block;
  }

  .header-nav .bl,
.header-nav .br {
    border: 0px solid transparent;
  }
  .header-nav nav .lang {
    width: 15%;
    float: left;
    padding: 20px;
  }
  .header-nav nav .facebook-link {
    float: right;
    padding: 0 20px;
  }

  .menu-list {
    margin-bottom: 10px;
  }

  .mega-menu-link {
    color: #393939;
    font-weight: bold;
  }
}
@keyframes dropdown {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes flyout {
  0% {
    opacity: 0;
    transform: scaleX(0);
  }
  100% {
    opacity: 1;
    transform: scaleX(1);
  }
}
@media all and (min-width: 950px) {
  .menu-bar > li.is-open ul:not(.no-flex).menu-depth-3 {
    display: none;
    top: 0;
    background: white;
    padding: 5px;
    position: absolute;
    min-width: auto !important;
  }

  .menu-bar > li.is-open ul:not(.no-flex).menu-depth-n {
    display: none;
    background: #fff;
    padding: 5px;
    position: absolute;
    top: 0;
    box-shadow: rgba(0, 0, 0, 0.016) 0 1.2px 1.9px -1px, rgba(0, 0, 0, 0.04) 0 3.3px 5.3px -1px, rgba(0, 0, 0, 0.086) 0 8.5px 12.7px -1px, rgba(0, 0, 0, 0.15) 0 30px 42px -1px;
    min-width: auto !important;
  }
  .menu-bar > li.is-open ul:not(.no-flex).menu-depth-n.show-hover {
    display: block !important;
  }

  .show-hover {
    display: block !important;
  }

  .menu-depth-3 {
    box-shadow: rgba(0, 0, 0, 0.016) 0 1.2px 1.9px -1px, rgba(0, 0, 0, 0.04) 0 3.3px 5.3px -1px, rgba(0, 0, 0, 0.086) 0 8.5px 12.7px -1px, rgba(0, 0, 0, 0.15) 0 30px 42px -1px;
  }

  .menu-depth-3 li {
    padding: 10px !important;
  }

  .menu-item-has-gran-children::after {
    content: "\e876";
    font-family: Linearicons-Free;
    width: 14px;
    height: 14px;
    font-size: 12px;
    position: absolute;
    right: -5px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media all and (min-width: 1350px) {
  .header-nav .wrapper {
    grid-template-columns: 320px auto;
  }

  .menu-link {
    padding: 20px 15px;
  }
}
@media all and (max-width: 950px) {
  .menu-depth-3 {
    left: 20px !important;
    min-width: 100% !important;
  }

  .menu-depth-n {
    left: 0px !important;
    min-width: 100% !important;
  }
  .menu-depth-n li a {
    border-bottom: 1px solid #35725f;
  }
}
.sidebar img {
  max-width: 100%;
}
.sidebar .sidebar-links {
  /*      border-color: #eaeaea;
       background-color: $bkggreen; */
  padding: 19px 35px;
  margin: 0 0 40px;
  overflow: hidden;
}
.sidebar .sidebar-links ul {
  list-style-type: none;
  padding-left: 0;
}
.sidebar .sidebar-links ul a {
  text-transform: uppercase;
  line-height: 1.1;
  position: relative;
  display: block;
  padding: 10px 15px;
}
.sidebar .sidebar-links ul a:hover {
  background-color: #f1f1f1;
  color: #377561;
  text-decoration: underline;
}
.sidebar .sidebar-news {
  /*    border-color: #eaeaea;
     background-color: $bkggreen; */
  padding: 19px 35px;
  margin: 0 0 40px;
  overflow: hidden;
}
.sidebar .sidebar-news a {
  color: #333;
  text-decoration: none;
}
.sidebar .sidebar-news a h5 {
  color: #377561;
}
.sidebar .sidebar-news a:hover p {
  color: #377561;
  text-decoration: underline;
}
.sidebar .sidebar-news ul {
  padding-left: 0;
}
.sidebar .sidebar-news ul li {
  display: block;
  margin-bottom: 10px;
  padding-top: 18px;
}
.sidebar .sidebar-news ul li:not(:first-child) {
  border-top: 1px solid #222;
}
.sidebar .sidebar-news .tab-post .imgwrap {
  display: inline-block;
  margin: 0 20px 0 0;
  max-width: 33%;
}
.sidebar .sidebar-news .tab-post .imgwrap img {
  height: auto;
}
.sidebar .sidebar-news .tab-post .meta {
  float: none;
  margin: 0;
  opacity: 0.5;
  font-style: italic;
}
.sidebar .sidebar-news .tab-post-right {
  display: inline-block;
  width: 57%;
  float: right;
}

.sidebar-bordered {
  border-right: 1px solid #ddd;
  padding-right: 20px;
}

button,
.button,
input[type=submit],
.btn.btn-primary {
  cursor: pointer;
  transition: background 150ms ease-in-out;
  -webkit-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  background: transparent;
  border: 2px solid #377561;
  border-radius: 1px;
  box-sizing: border-box;
  color: #377561;
  font-family: -apple-system, BlinkMacSystemFont, "PT Sans", "Poppins", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  letter-spacing: 1px;
  line-height: var(--heading-line-height);
  outline: none;
  padding: 10px;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  transition: all 0.5s ease-out;
}
@media only screen and (min-width: 56.25em) {
  button,
.button,
input[type=submit],
.btn.btn-primary {
    letter-spacing: 2px;
  }
}
button:hover,
.button:hover,
input[type=submit]:hover,
.btn.btn-primary:hover {
  background: #377561;
  border: 2px solid #377561;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
button:visited,
.button:visited,
input[type=submit]:visited,
.btn.btn-primary:visited {
  color: #000;
  text-decoration: none;
}
button:focus,
.button:focus,
input[type=submit]:focus,
.btn.btn-primary:focus {
  outline: none;
}
button.red,
.button.red,
input[type=submit].red,
.btn.btn-primary.red {
  border: 1px solid #e2552e;
}
button.red:hover,
.button.red:hover,
input[type=submit].red:hover,
.btn.btn-primary.red:hover {
  background: #e2552e;
  border: 2px solid #e2552e;
  color: #fff !important;
}
button.red:hover, button.red:focus, button.red:active,
.button.red:hover,
.button.red:focus,
.button.red:active,
input[type=submit].red:hover,
input[type=submit].red:focus,
input[type=submit].red:active,
.btn.btn-primary.red:hover,
.btn.btn-primary.red:focus,
.btn.btn-primary.red:active {
  text-decoration: none !important;
}

.document .btn-primary {
  background: #377561;
  color: #fff;
  /* &:visited {
      color: #fff !important;
  } */
}
.document .btn-primary:hover {
  background: transparent;
  color: #377561;
}

.secondaryAction {
  margin-left: 20px;
}

.linked-button {
  text-decoration: underline;
}

.edit-mode-btns {
  padding-left: 15px;
  padding-right: 15px;
}

/* BACK-TO-TOP */
.ksw-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 40px;
  right: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: rgba(55, 117, 97, 0.8) url(/static/landing/img/ksw-top-arrow.svg) no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}
.ksw-top.ksw-is-visible, .ksw-top.ksw-fade-out, .no-touch .ksw-top:hover {
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}
.ksw-top.ksw-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1;
  z-index: 9999;
}
.ksw-top.ksw-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  opacity: 0.5;
}
.no-touch .ksw-top:hover, .ksw-top:hover {
  background-color: #377561;
  opacity: 1;
}
@media only screen and (min-width: 56.25em) {
  .ksw-top {
    right: 20px;
    bottom: 20px;
  }
}
@media only screen and (min-width: 100em) {
  .ksw-top {
    height: 50px;
    width: 50px;
    right: 30px;
    bottom: 30px;
  }
}

#calendar {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100%;
  height: auto;
  overflow: hidden;
}
@media only screen and (min-width: 56.25em) {
  #calendar {
    width: 420px;
    height: 620px;
  }
}
#calendar .header {
  width: 100%;
  height: auto;
  border: 1px solid #8fe3d3;
  text-align: center;
  position: relative;
  z-index: 100;
}
@media only screen and (min-width: 56.25em) {
  #calendar .header {
    height: 50px;
    width: 420px;
  }
}
#calendar .header h1 {
  color: #000;
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 50px;
  letter-spacing: 1px;
}
#calendar .left, #calendar .right {
  position: absolute;
  width: 0px;
  height: 0px;
  border-style: solid;
  top: 50%;
  margin-top: -7.5px;
  cursor: pointer;
}
#calendar .left {
  border-width: 7.5px 10px 7.5px 0;
  border-color: transparent black transparent transparent;
  left: 20px;
}
#calendar .right {
  border-width: 7.5px 0 7.5px 10px;
  border-color: transparent transparent transparent black;
  right: 20px;
}
#calendar .month {
  background: #fff;
  overflow: hidden;
  opacity: 0;
}
#calendar .month.new {
  -webkit-animation: fadeIn 1s ease-out;
  opacity: 1;
}
#calendar .month.in.next {
  -webkit-animation: moveFromTopFadeMonth 0.4s ease-out;
  -moz-animation: moveFromTopFadeMonth 0.4s ease-out;
  animation: moveFromTopFadeMonth 0.4s ease-out;
  opacity: 1;
}
#calendar .month.out.next {
  -webkit-animation: moveToTopFadeMonth 0.4s ease-in;
  -moz-animation: moveToTopFadeMonth 0.4s ease-in;
  animation: moveToTopFadeMonth 0.4s ease-in;
  opacity: 1;
}
#calendar .month.in.prev {
  -webkit-animation: moveFromBottomFadeMonth 0.4s ease-out;
  -moz-animation: moveFromBottomFadeMonth 0.4s ease-out;
  animation: moveFromBottomFadeMonth 0.4s ease-out;
  opacity: 1;
}
#calendar .month.out.prev {
  -webkit-animation: moveToBottomFadeMonth 0.4s ease-in;
  -moz-animation: moveToBottomFadeMonth 0.4s ease-in;
  animation: moveToBottomFadeMonth 0.4s ease-in;
  opacity: 1;
}
#calendar .week {
  background: #ffffff;
}
#calendar .day {
  display: inline-block;
  border: 1px solid transparent;
  width: 45px;
  padding: 10px;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  background: #ffffff;
  position: relative;
  z-index: 100;
}
@media only screen and (min-width: 56.25em) {
  #calendar .day {
    width: 60px;
  }
}
#calendar .day.other {
  color: #fff;
}
#calendar .day.today,
#calendar .day.selected {
  color: #377561;
}
#calendar .day.selected {
  border: 1px solid #377561;
}
#calendar .day-name {
  font-size: 9px;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: #000;
  letter-spacing: 0.7px;
}
#calendar .day-number {
  font-size: 22px;
  letter-spacing: 1.5px;
}
@media only screen and (min-width: 56.25em) {
  #calendar .day-number {
    font-size: 24px;
  }
}
#calendar .day-number:hover {
  color: #377561;
}
#calendar .day .day-events {
  list-style: none;
  margin-top: 3px;
  text-align: center;
  height: 12px;
  line-height: 6px;
  overflow: hidden;
}
#calendar .day .day-events span {
  vertical-align: top;
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 5px;
  height: 5px;
  line-height: 5px;
  margin: 0 1px;
}
#calendar .blue {
  background: #9ccaeb;
}
#calendar .orange {
  background: #f7a700;
}
#calendar .green {
  background: #377561;
}
#calendar .yellow {
  background: #f9e900;
}
#calendar .arrow {
  display: none;
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: -2px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #a4a4a4 transparent;
  transition: all 0.7s ease;
}
#calendar .legend {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  background: #3c3c3c;
  line-height: 30px;
}
#calendar .entry {
  position: relative;
  padding: 0 0 0 25px;
  font-size: 13px;
  display: inline-block;
  line-height: 30px;
  background: transparent;
}
#calendar .entry:after {
  position: absolute;
  content: "";
  height: 5px;
  width: 5px;
  top: 12px;
  left: 14px;
}
#calendar .entry.blue:after {
  background: #9ccaeb;
}
#calendar .entry.orange:after {
  background: #f7a700;
}
#calendar .entry.green:after {
  background: #377561;
}
#calendar .entry.yellow:after {
  background: #f9e900;
}

.details {
  position: absolute;
  width: 420px;
  /*       background: rgba(164, 164, 164, 1); */
  margin-top: 5px;
  border-radius: 4px;
  top: 0;
  left: 500px;
}

.details.in {
  -webkit-animation: moveFromTopFade 0.5s ease both;
  -moz-animation: moveFromTopFade 0.5s ease both;
  animation: moveFromTopFade 0.5s ease both;
}

.details.out {
  -webkit-animation: moveToTopFade 0.5s ease both;
  -moz-animation: moveToTopFade 0.5s ease both;
  animation: moveToTopFade 0.5s ease both;
}

.events {
  min-height: 75px;
  padding: 7px 0;
}

.events.in {
  -webkit-animation: fadeIn 0.3s ease both;
  -moz-animation: fadeIn 0.3s ease both;
  animation: fadeIn 0.3s ease both;
}

.events.in {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.details.out .events {
  -webkit-animation: fadeOutShrink 0.4s ease both;
  -moz-animation: fadeOutShink 0.4s ease both;
  animation: fadeOutShink 0.4s ease both;
}

.events.out {
  -webkit-animation: fadeOut 0.3s ease both;
  -moz-animation: fadeOut 0.3s ease both;
  animation: fadeOut 0.3s ease both;
}

.event {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.5px;
  padding: 2px 16px;
  vertical-align: top;
  border-bottom: 1px solid #DDD;
  padding: 50px 0;
  display: block;
}

.event.empty {
  color: #eee;
}

.event-category {
  height: 10px;
  width: 10px;
  display: inline-block;
  margin: 6px 0 0;
  vertical-align: top;
}

.event span {
  display: inline-block;
  padding: 0 0 0 7px;
}

.event-details {
  padding: 40px 0;
  border-bottom: 1px solid #f1f1f1;
}
.event-details h3 {
  margin: 0 auto 20px;
}
.event-details img {
  object-fit: cover;
  width: 100%;
}
.event-details hr {
  height: 2px;
  color: #8fe3d3;
  background-color: #8fe3d3;
  margin: 5px auto;
}
.event-details .description {
  padding: 20px 0;
}

.event-details-wrap .event-details:first-child {
  padding-top: 0;
}

.event-info {
  color: #377561;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* Animations are cool!  */
@-webkit-keyframes moveFromTopFade {
  from {
    opacity: 0.3;
    height: 0px;
    margin-top: 0px;
    -webkit-transform: translateY(-100%);
  }
}
@-moz-keyframes moveFromTopFade {
  from {
    height: 0px;
    margin-top: 0px;
    -moz-transform: translateY(-100%);
  }
}
@keyframes moveFromTopFade {
  from {
    height: 0px;
    margin-top: 0px;
    transform: translateY(-100%);
  }
}
@-webkit-keyframes moveToTopFade {
  to {
    opacity: 0.3;
    height: 0px;
    margin-top: 0px;
    opacity: 0.3;
    -webkit-transform: translateY(-100%);
  }
}
@-moz-keyframes moveToTopFade {
  to {
    height: 0px;
    -moz-transform: translateY(-100%);
  }
}
@keyframes moveToTopFade {
  to {
    height: 0px;
    transform: translateY(-100%);
  }
}
@-webkit-keyframes moveToTopFadeMonth {
  to {
    opacity: 0;
    -webkit-transform: translateY(-30%) scale(0.95);
  }
}
@-moz-keyframes moveToTopFadeMonth {
  to {
    opacity: 0;
    -moz-transform: translateY(-30%);
  }
}
@keyframes moveToTopFadeMonth {
  to {
    opacity: 0;
    -moz-transform: translateY(-30%);
  }
}
@-webkit-keyframes moveFromTopFadeMonth {
  from {
    opacity: 0;
    -webkit-transform: translateY(30%) scale(0.95);
  }
}
@-moz-keyframes moveFromTopFadeMonth {
  from {
    opacity: 0;
    -moz-transform: translateY(30%);
  }
}
@keyframes moveFromTopFadeMonth {
  from {
    opacity: 0;
    -moz-transform: translateY(30%);
  }
}
@-webkit-keyframes moveToBottomFadeMonth {
  to {
    opacity: 0;
    -webkit-transform: translateY(30%) scale(0.95);
  }
}
@-moz-keyframes moveToBottomFadeMonth {
  to {
    opacity: 0;
    -webkit-transform: translateY(30%);
  }
}
@keyframes moveToBottomFadeMonth {
  to {
    opacity: 0;
    -webkit-transform: translateY(30%);
  }
}
@-webkit-keyframes moveFromBottomFadeMonth {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30%) scale(0.95);
  }
}
@-moz-keyframes moveFromBottomFadeMonth {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30%);
  }
}
@keyframes moveFromBottomFadeMonth {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30%);
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOut {
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeOut {
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeOutShink {
  to {
    opacity: 0;
    padding: 0px;
    height: 0px;
  }
}
@-moz-keyframes fadeOutShink {
  to {
    opacity: 0;
    padding: 0px;
    height: 0px;
  }
}
@keyframes fadeOutShink {
  to {
    opacity: 0;
    padding: 0px;
    height: 0px;
  }
}
.c-event_left {
  min-height: 250px;
  padding-bottom: 28%;
  position: relative;
}

.o-background {
  display: block;
  background-position: 50%;
  background-size: cover;
}

.o-background, .o-background.-overlay::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.c-event_image_half {
  width: 50%;
}

.c-event_date {
  display: inline-block;
  background-color: #8fe3d3;
  text-align: center;
  font-family: Larsseit, sans-serif;
  position: absolute;
  right: 0;
  bottom: 0;
}

.c-event_date_half {
  height: 50%;
}
.c-event_date_half:before {
  display: inline-block;
  height: 100%;
  content: "";
  vertical-align: middle;
}
.c-event_date_half:first-child {
  position: relative;
}
.c-event_date_half:first-child:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid;
  opacity: 0.1;
}
.c-event_date_half:last-child {
  padding-top: 10%;
}
.c-event_date_half > * {
  display: inline-block;
  vertical-align: middle;
}

.c-event_date {
  width: 50%;
  height: 100%;
  top: 0;
}

.c-event_date_arrow {
  position: absolute;
  bottom: -0.5625rem;
  left: 50%;
  margin-left: -0.5625rem;
  width: 1.125rem;
  height: 1.125rem;
  fill: #000;
}

.upcoming-events h3 {
  margin-top: 0;
}

.card.document {
  padding: 30px;
  margin: 40px 0;
  background: rgba(180, 193, 190, 0.24) url("/static/landing/img/PDF-logo.png") no-repeat 30px center;
  background-size: 60px !important;
  border: 1px solid rgba(204, 204, 204, 0.2);
  border-radius: 0;
}
.card.document .card-body {
  padding-left: 80px;
  align-items: center;
}
.card.document .btn.btn-primary {
  line-height: 1.9;
  letter-spacing: 0 !important;
}
.card.document p a {
  background-image: none !important;
}
.card.document p a:hover {
  text-decoration: underline;
}
.card.document.file {
  background: rgba(180, 193, 190, 0.24) url("/static/landing/img/archive.svg") no-repeat 30px center;
}
.card.document.doc {
  background: rgba(180, 193, 190, 0.24) url("/static/landing/img/doc.png") no-repeat 30px center;
}

[data-toggle=collapse] {
  color: #212121;
  position: relative;
  cursor: pointer;
}
[data-toggle=collapse]:after {
  content: "+";
  position: absolute;
  right: 30px;
  top: 0;
  line-height: 1;
}
[data-toggle=collapse]:hover {
  color: #377561;
}
[data-toggle=collapse]:hover:after {
  color: #377561;
}

.collapse.in {
  margin: 20px 0;
}

div[data-children=".card"] {
  border-bottom: 2px solid #212121;
  margin: 30px 0 20px;
}
div[data-children=".card"]:hover {
  border-color: #377561;
}
div[data-children=".card"]:first-of-type {
  border-top: 2px solid #212121;
  padding-top: 20px;
}

div[data-toggle=collapse][aria-expanded=true] {
  border-bottom: 2px solid #f1f1f1;
}
div[data-toggle=collapse][aria-expanded=true]:after {
  content: "-";
}

body .alert {
  margin-bottom: 0 !important;
  position: relative;
  top: 60px;
  z-index: 97;
  width: 100%;
  right: 0;
}
@media only screen and (min-width: 56.25em) {
  body .alert {
    position: absolute;
    top: 150px;
    width: 45%;
  }
}
body.dashboard .alert {
  position: fixed;
  top: 60px;
  width: 100%;
  min-height: 100px;
}
body.dashboard .alert .close {
  font-size: 2rem;
  width: auto;
}

.dashboard .loading-wrapper {
  background: rgba(177, 204, 180, 0.9);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 999;
}
@media only screen and (min-width: 56.25em) {
  .dashboard .loading-wrapper {
    height: 100%;
    position: absolute;
  }
}
.dashboard .loading-screen {
  position: absolute;
  top: 45%;
  left: 57.5%;
  transform: translate(-50%, -50%);
  display: block;
}

.paddle, .player_two, .player_one {
  height: 40px;
  width: 3px;
  background-color: white;
  position: relative;
}

.player_one {
  left: -180px;
  animation: movePaddleOne 4s infinite;
}

.player_two {
  left: 20px;
  animation: movePaddleTwo 4s infinite;
}

.ball {
  position: relative;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background-color: white;
  animation: moveBall 4s infinite linear;
}

@keyframes movePaddleOne {
  0%, 100% {
    transform: translate(0px, 100px);
  }
  25% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 0px);
  }
  75% {
    transform: translate(0px, 100px);
  }
}
@keyframes movePaddleTwo {
  0%, 100% {
    transform: translate(0px, -50px);
  }
  25% {
    transform: translate(0px, 10px);
  }
  50% {
    transform: translate(0px, 0px);
  }
  75% {
    transform: translate(0px, 50px);
  }
}
@keyframes moveBall {
  0%, 100% {
    transform: translate(-180px, 30px);
  }
  25% {
    transform: translate(18px, -25px);
  }
  50% {
    transform: translate(-180px, -55px);
  }
  75% {
    transform: translate(18px, 15px);
  }
}
/* Acordeon styles */
.tab,
.map-filters {
  position: relative;
  margin-bottom: 1px;
  width: 100%;
  overflow: hidden;
  /* :checked */
  /* Icon */
}
.tab input,
.map-filters input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.tab label,
.map-filters label {
  position: relative;
  color: #212121;
  display: block;
  padding: 0 0 0 1em;
  border-top: 2px solid #212121;
  font-weight: bold;
  line-height: 3;
  cursor: pointer;
}
.tab label:hover,
.map-filters label:hover {
  color: #377561;
}
.tab .tab-content,
.map-filters .tab-content {
  max-height: 0;
  overflow: hidden;
  background: transparent;
  -webkit-transition: max-height 0.35s;
  -o-transition: max-height 0.35s;
  transition: max-height 0.35s;
}
.tab .tab-content p,
.map-filters .tab-content p {
  margin: 1em;
}
.tab input:checked ~ .tab-content,
.map-filters input:checked ~ .tab-content {
  max-height: 10em;
}
.tab label::after,
.map-filters label::after {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 3em;
  height: 3em;
  line-height: 3;
  text-align: center;
}
.tab input[type=checkbox] + label::after,
.map-filters input[type=checkbox] + label::after {
  content: "+";
}
.tab input[type=checkbox]:checked + label::after,
.map-filters input[type=checkbox]:checked + label::after {
  content: "-";
}
.tab input[type=checkbox]:checked + label,
.map-filters input[type=checkbox]:checked + label {
  color: #377561;
}

/* customs */
.dashboard .tab label {
  text-transform: none;
  border-top: 0px solid #000;
  border-bottom: 2px solid #212121;
}
.dashboard .tab label:after {
  width: 3rem;
  height: 3rem;
  line-height: 1.6;
  text-align: center;
}

.main-breadcrumbs span {
  margin: 0 5px 0 0;
  position: relative;
  text-transform: uppercase;
  font-size: 14px;
}
.main-breadcrumbs a {
  color: #55c1aa;
}

.gallery-cover {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.gallery-cover figure {
  height: 100%;
  position: relative;
  transition: all 0.6s ease-out;
}
.gallery-cover figure img {
  object-fit: cover;
  transition: all 0.6s ease-in-out;
}
.gallery-cover figure figcaption {
  color: #000;
  text-align: center;
  margin: 20px 0;
}
.gallery-cover figure:hover img {
  opacity: 0.9;
  transform: scale(1.1);
}

.gallery-wrap {
  background-color: #d2864f;
  overflow: hidden;
  position: relative;
}
.gallery-wrap img {
  height: 400px;
  object-fit: cover;
  opacity: 0.9;
  transition: all 0.6s ease-in-out;
}
.gallery-wrap img:hover {
  transform: scale(1.1);
}

.doc-wrap {
  padding: 10px 15px;
  margin: 0 0 20px !important;
  background: rgba(204, 204, 204, 0.1);
  border: 1px solid rgba(204, 204, 204, 0.2);
  border-radius: 0;
}

#news-section {
  padding: 20px 0;
}
@media only screen and (min-width: 56.25em) {
  #news-section {
    padding: 80px 0;
  }
  #news-section .news-articles-wrapper {
    grid-column: 1/3;
  }
}

.calendar {
  background: #fff;
  padding: 20px 0;
}
.calendar .page-content {
  padding: 40px 0;
}
@media screen and (max-width: 610px) {
  .calendar .cols-2 {
    display: grid;
  }
  .calendar .cols-2 #calendar {
    order: 1;
  }
  .calendar .cols-2 .event-details-wrap {
    order: 0;
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 56.25em) {
  .calendar {
    padding: 80px 0 0;
  }
  .calendar .page-content {
    padding: 80px 0;
  }
}

@media only screen and (min-width: 56.25em) {
  .promo-col {
    grid-column: 4/5;
  }
}

.poll {
  position: relative;
  background-color: #377561;
  padding: 20px 0;
}
@media only screen and (min-width: 56.25em) {
  .poll {
    padding: 100px 0 0;
  }
}
.poll .poll-background-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.05;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.poll .cols-2 > div {
  padding-bottom: 40px;
}
@media only screen and (min-width: 56.25em) {
  .poll .cols-2 > div {
    padding-bottom: 100px;
  }
}
.poll .cols-2 > div:first-child {
  width: 80%;
  align-self: center;
}
.poll .cols-2 > div:first-child h2, .poll .cols-2 > div:first-child h4 {
  color: #fff;
}
.poll .cols-2 > div:first-child h4 {
  line-height: 1.5;
}
.poll .sep {
  height: 1px;
  width: 70px;
  background-color: #cccccc;
  margin: 20px 0;
}

/* Resources */
.resources {
  background: #ebf4f2;
  position: relative;
  padding: 20px 0;
}
@media only screen and (min-width: 56.25em) {
  .resources {
    padding: 100px 0;
  }
}

/* commons */
.boxed-link {
  height: 270px;
  background: #fff;
  margin: 16px auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  position: relative;
  text-align: center;
}
.boxed-link:hover {
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px #498952, 0 0 12px 0 rgba(0, 163, 189, 0.3);
  outline: none;
}
.boxed-link a {
  text-decoration: none;
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-decoration: none;
  /* No underlines on the link */
  /*         z-index: 10; Places the link above everything else in the div */
  background-color: #FFF;
  /* Fix to make div clickable in IE */
  filter: alpha(opacity=1);
  /* Fix to make div clickable in IE */
}
.boxed-link .resource-tile-body {
  min-height: 180px;
  padding: 20px;
}
.boxed-link img {
  height: 80px;
}
.boxed-link p {
  font-weight: 400;
  color: #425b76;
  text-align: center;
  margin: 0;
}

.home-map iframe {
  border: none;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}
@media only screen and (min-width: 56.25em) {
  .home-map iframe {
    width: 40%;
  }
}

.featured-block {
  margin-bottom: 30px;
  position: relative;
  display: block;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);
  transition: all 300ms ease;
}
@media only screen and (min-width: 56.25em) {
  .featured-block {
    margin-bottom: 0;
  }
}
.featured-block a {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #FFF;
  filter: alpha(opacity=1);
}
.featured-block a:hover {
  text-decoration: none;
}
.featured-block .inner-box {
  padding: 25px 30px;
}
@media only screen and (min-width: 56.25em) {
  .featured-block .inner-box {
    padding: 40px 30px 25px;
  }
}
.featured-block:hover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07), 0 15px 30px 0 rgba(0, 0, 0, 0.07);
}
.featured-block .icon-box .lnr {
  font-size: 3rem;
  color: #377561;
}
.featured-block p {
  font-weight: 400;
  color: #757575;
}
.featured-block .content h3 {
  color: #333;
}
.featured-block .content h3:hover {
  color: #377561;
  text-decoration: none;
}
.featured-block .read-more2 {
  position: relative;
  display: block;
  padding: 15px 0 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 25px;
}
@media screen and (min-width: 1200px) {
  .featured-block .read-more2 .cols-2 {
    grid-template-columns: 150px auto;
  }
}
.featured-block .read-more2 span {
  color: #757575;
}
.featured-block .read-more2 span.right {
  text-align: right;
}
.featured-block .read-more2:hover {
  text-decoration: none;
}
.featured-block .read-more2:hover span {
  color: #377561;
}

.all-events {
  display: none;
}

.home .all-events {
  display: inline-block;
}

.header-section {
  overflow: hidden;
  background-color: #000;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}
@media only screen and (min-width: 56.25em) {
  .header-section {
    background-attachment: fixed;
    overflow: hidden;
    height: 20vh;
  }
}
.header-section .header-content {
  display: block;
  overflow: hidden;
  width: 100vw;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}
@media only screen and (min-width: 56.25em) {
  .header-section .header-content {
    position: absolute;
  }
}
@media only screen and (min-width: 56.25em) {
  .header-section .header-content {
    height: 20vh;
  }
}
.header-section .header-text {
  color: #fff;
  position: relative;
  z-index: 50;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  top: auto;
}
@media only screen and (min-width: 56.25em) {
  .header-section .header-text {
    height: 100vh;
  }
}
@media only screen and (min-width: 56.25em) {
  .header-section .header-text.active {
    display: block;
  }
}
@media only screen and (min-width: 56.25em) {
  .header-section .header-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background-position: 50%;
    background-size: cover;
    overflow: hidden;
  }
  .header-section .header-video {
    height: 50vh;
    min-width: 100vw;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.header-section .menu {
  position: absolute;
  z-index: 100;
  width: 100vw;
  box-shadow: none;
  line-height: 10px;
}
.header-section .menu .wrap.cc-menu {
  padding: 20px 40px 25px;
  z-index: 900;
  width: 100vw;
  background-color: transparent;
}
@media only screen and (min-width: 56.25em) {
  .header-section .menu .wrap.cc-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 40px 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 20px;
    grid-row-gap: 16px;
    -ms-grid-columns: 4fr 1fr;
    grid-template-columns: 4fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
}

.black-transp {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  /*  background-color: rgba(0, 0, 0, 0.5);*/
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 35%, #273938 99%);
}

@media only screen and (min-width: 56.25em) {
  .header-section {
    height: 100vh;
  }
  .header-section .header-content {
    height: 100vh;
  }
  .header-section .header-text {
    height: 100vh;
    text-align: left;
    display: none;
  }
  .header-section .header-text.active {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .header-section .header-video {
    height: 100vh;
  }

  .home .header-section,
.header-section {
    height: 100vh;
  }
  .home .header-section .header-content,
.header-section .header-content {
    height: 100vh;
  }
  .home .header-section .header-text,
.header-section .header-text {
    height: 100vh;
  }
  .home .header-section .header-text2,
.header-section .header-text2 {
    height: 100%;
  }
}
@media screen and (max-width: 900px) {
  .header-headline h1 {
    font-size: 15px;
    position: absolute;
    color: #fff;
    font-weight: bold;
    z-index: 999;
    top: -90px;
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .header-section video {
    width: 100%;
    margin-top: 70px;
  }
}
@media only screen and (min-width: 56.25em) {
  .header-headline-wrap {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}

.intro-icon {
  border-radius: 50%;
  border: 1px solid white;
  padding: 10px;
  width: auto;
  display: inline-block;
  margin-bottom: 10px;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .intro-icon img {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (min-width: 112em) {
  .intro-icon {
    padding: 20px;
  }
}

.intro-cols {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.intro-cols .intro-columns > div {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  position: relative;
}
@media screen and (max-width: 900px) {
  .intro-cols .intro-columns > div {
    height: 90%;
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 56.25em) {
  .intro-cols .intro-columns > div {
    border: none;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
}
@media only screen and (min-width: 112em) {
  .intro-cols .intro-columns > div {
    padding: 30px 40px;
  }
}
.intro-cols .intro-columns > div h5 {
  color: #fff;
  cursor: pointer;
}
.intro-cols .intro-columns > div h5 a {
  color: #fff;
  cursor: pointer;
}
.intro-cols .intro-columns > div h5 a:hover {
  text-decoration: underline;
}
.intro-cols p, .intro-cols p a {
  color: #fff !important;
}
.intro-cols .intro-col1 {
  border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.intro-cols .intro-col1:hover {
  background-color: #55c1aa;
}
.intro-cols .intro-col2:hover {
  background-color: #40a085;
}
.intro-cols .intro-col3:hover {
  background-color: #377561;
}
@media screen and (max-width: 900px) {
  .intro-cols .intro-col1,
.intro-cols .intro-col2,
.intro-cols .intro-col3 {
    position: relative;
    top: 10px;
    margin: auto;
    width: 100%;
  }
}
.intro-cols a:hover {
  text-decoration: none;
}
.intro-cols div {
  align-self: center;
}

.w-background-video {
  position: relative;
  overflow: hidden;
  height: 500px;
  color: white;
}
.w-background-video > video {
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
  right: -100%;
  bottom: -100%;
  top: -100%;
  left: -100%;
  margin: auto;
  min-width: 100%;
  min-height: 100%;
  z-index: -100;
}

.o-background, .o-background.-overlay::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.o-background {
  display: block;
  background-position: 50%;
  background-size: cover;
  overflow: hidden;
}

@media only screen and (min-width: 56.25em) {
  .o-background_video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    vertical-align: middle;
    display: inline-block;
  }
}

@media only screen and (min-width: 56.25em) {
  .header-text-main {
    position: absolute;
    width: 100%;
    top: 40%;
    z-index: 9999;
  }
  .header-text-main h1 {
    color: #fff;
  }
}
.first-section {
  background-color: #f1f1f1;
}
@media only screen and (min-width: 56.25em) {
  .first-section .wrapper {
    padding-left: 0;
    position: relative;
  }
}
@media only screen and (min-width: 56.25em) {
  .first-section .page-title {
    margin: 0 -400px 0 0;
    padding: 0 0 0 80px;
    border-style: solid;
    border-width: 0 0 0 50px;
    border-color: #377561;
    width: 80%;
  }
  .first-section .page-title h1 {
    font-size: 4vw;
  }
}

.second-section .wrapper {
  border-style: solid;
  border-width: 0 0 0 50px;
  border-color: #377561;
}
@media only screen and (min-width: 56.25em) {
  .second-section .wrapper {
    padding: 0 0 0 80px;
  }
}

/* sidebar */
.dashboard .page-content {
  padding: 100px 0;
}
@media only screen and (min-width: 56.25em) {
  .dashboard .page-content {
    /* background: linear-gradient(to left, $color-bkg 0%,$color-bkg 50%,#f4f7fc 75%,white 40%,white 40%); */
    padding: 100px 0 150px;
  }
}
.dashboard .ksw-form {
  background: #fff;
  border: 0px solid #fff;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
}
.dashboard .sidebar {
  border-radius: 10px;
  position: relative;
}
@media only screen and (min-width: 100em) {
  .dashboard .sidebar {
    left: -60px;
    min-height: 600px;
  }
}
.dashboard .sidebar .sidebar-inner {
  background: white;
  padding: 30px 10px;
  z-index: 1;
}
@media only screen and (min-width: 56.25em) {
  .dashboard .sidebar .sidebar-inner {
    position: fixed;
    width: 235px;
  }
}
.dashboard .sidebar h3 {
  text-transform: uppercase;
}
.dashboard .sidebar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dashboard .sidebar ul li {
  border-bottom: 1px solid #f1f1f1;
  padding: 8px 0 8px 10px;
}
.dashboard .sidebar ul li:hover {
  background: #f1f1f1;
}
.dashboard .sidebar ul li a:hover {
  text-decoration: none;
}
.dashboard .sidebar ul li a:hover span {
  text-decoration: underline;
}
.dashboard .sidebar ul li.active {
  background: #377561;
}
.dashboard .sidebar ul li.active a, .dashboard .sidebar ul li.active i {
  color: #fff;
}
.dashboard .sidebar ul li a {
  color: #333;
}
.dashboard .sidebar ul li i {
  color: #333;
  margin-right: 10px;
}
.dashboard .side-content {
  order: 4;
}

/* main content */
.box-link {
  background: #fff;
  cursor: pointer;
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  width: 200px;
  height: 200px;
}
.box-link:hover {
  box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  -webkit-box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  top: -2px;
}
.box-link h3 {
  font-weight: bold;
}
.box-link i {
  font-size: 1.5rem;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.boxed-link.dashboard {
  box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, 0.2);
  position: relative;
  height: auto;
  min-height: 270px;
  margin: 0;
  text-align: left;
  margin-bottom: 30px;
  height: 100%;
  border: 1px solid #fff;
  background: #fff;
}
.boxed-link.dashboard:hover {
  box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, 0.2);
  -webkit-box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  top: -2px;
}
.boxed-link.dashboard .img-wrapper {
  margin: 30px 0;
}
.boxed-link.dashboard.add-new-adress {
  min-height: 100px;
  height: 180px;
}
.boxed-link.dashboard h3 {
  color: #212121;
}
.boxed-link.dashboard .button-wrapper {
  background: #377561;
  position: absolute;
  bottom: 0;
  height: 70px;
  width: 100%;
  text-align: center;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: grid;
  align-items: center;
}
.boxed-link.dashboard .button-wrapper a {
  height: auto;
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-45%, -50%);
  background: none;
  border: 0px solid #fff;
  color: #fff;
  width: auto;
  letter-spacing: 1px;
}
@media screen and (min-width: 900px) and (max-width: 1200px) {
  .boxed-link.dashboard .button-wrapper a {
    font-size: 0.8rem;
  }
}
.boxed-link.dashboard .button-wrapper:hover {
  background: #55c1aa;
}
.boxed-link.dashboard .header-wrapper {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 20px;
  border-bottom: 1px solid #f1f1f1;
}
.boxed-link.dashboard .header-wrapper h4 {
  margin-top: 0;
}

.section {
  padding: 20px 0;
}
@media only screen and (min-width: 56.25em) {
  .section {
    padding: 50px 0;
  }
}

.cart {
  width: 100%;
  padding: 30px;
  border-left: 1px solid #ddd;
  height: 100%;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .cart {
    border-bottom: 1px solid #ddd;
  }
}
.cart h4 {
  border-bottom: 3px solid #000;
}
.cart .button-wrapper {
  margin: 40px 0;
  text-align: center;
}
.cart .img-wrapper {
  font-weight: bold;
  text-align: center;
}

@media only screen and (min-width: 56.25em) {
  .order-listing {
    padding: 20px;
  }
}

.order-item-list {
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
  border-radius: 0.375rem;
}
.order-item-list .order-item {
  background: #fff;
  padding: 30px 20px;
}
@media only screen and (min-width: 56.25em) {
  .order-item-list .order-item {
    transition: all 0.6s ease-out;
    transform: perspective(1px) scale(1) translateZ(0);
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}
.order-item-list .order-item:nth-child(even) {
  background: #f7fcfc;
}
.order-item-list .order-item:first-child {
  border-collapse: separate;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.order-item-list .order-item:last-child {
  border-collapse: separate;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.order-item-list .order-item:hover {
  border-radius: 0.375rem;
  position: relative;
  z-index: 9999;
  transform: scale(1.028);
}
.order-item-list .order-item span {
  color: #8A8383;
  font-size: 0.9em;
  letter-spacing: 1px;
}
.order-item-list .order-item .order-value {
  color: #377561;
}
.order-item-list .order-item .pdf {
  margin-top: 30px;
}
@media only screen and (min-width: 56.25em) {
  .order-item-list .order-item .pdf {
    margin-top: -11px;
  }
}
.order-item-list .lnr-checkmark-circle {
  color: #377561;
  padding-right: 8px;
}
.order-item-list .lnr-warning {
  color: #e2552e;
  padding-right: 8px;
}
.order-item-list input[type=checkbox] {
  width: 20px;
  height: 20px;
}

.total-pay {
  border: 0 !important;
  background: transparent !important;
  display: inline-block;
}

#twispayframe {
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.info-box-bordered {
  border: 1px solid #222;
  padding: 2px 10px;
  margin-left: 10px;
}

.add-new {
  font-size: 2rem;
}

.formsets .ksw-form {
  margin-bottom: 20px;
}

.download-icon:hover h5 {
  color: #377561;
}

/* Mobile navigation */
@media screen and (max-width: 760px) {
  .sidebar-dashboard {
    background: white;
    height: 100vh;
    width: 50px;
  }
  .sidebar-dashboard .sidebar-inner {
    background: #fff;
    left: 0;
    height: 100vh;
    position: fixed;
    padding-top: 0 !important;
    transition: all 0.6s ease-out;
  }
  .sidebar-dashboard .sidebar-inner .dashboard-links {
    transition: all 0.6s ease-out;
  }
  .sidebar-dashboard .sidebar-inner .dashboard-links li {
    border-bottom: 1px solid #ddd;
    padding: 10px 0 10px 10px;
  }
  .sidebar-dashboard .sidebar-inner .dashboard-links li i {
    font-size: 2rem;
  }
  .sidebar-dashboard .sidebar-inner .dashboard-links li span {
    display: none;
    position: relative;
    top: -5px;
  }
  .sidebar-dashboard .sidebar-inner.in .dashboard-links li span {
    display: inline-block;
  }
  .sidebar-dashboard .expand-menu {
    text-decoration: none !important;
    outline: none !important;
  }

  .dashboard .page-content > .wrapper {
    padding-left: 0;
  }
  .dashboard .page-content {
    background: #f3f6fb !important;
    padding: 70px 0;
  }
  .dashboard .cols-small-sidebar-left {
    grid-template-columns: 80px auto;
  }
  .dashboard .side-content .ksw-form {
    margin-bottom: 30px;
  }
}
.expand-menu {
  display: none;
}

@media screen and (max-width: 480px) {
  .expand-menu {
    display: block;
  }

  .dashboard #footer {
    padding-left: 70px;
  }

  .footer-img {
    position: absolute;
    top: -50px;
    left: 0;
  }
}
.index-box {
  border: 1px solid #222;
  padding: 2px 10px;
  margin-left: 10px;
}

.map-filters ul {
  list-style-type: none;
}
.map-filters ul li a {
  color: #7b7b7b;
  display: block;
  padding: 10px 15px;
}
.map-filters ul li a:hover {
  background-color: #f1f1f1;
  color: #377561;
  text-decoration: underline;
}

.map-cols {
  position: relative;
}
.map-cols #map {
  z-index: 1;
  height: 100vh;
}
.map-cols #buttons {
  margin-top: 30px;
}
.map-cols .map-info {
  position: absolute;
  top: 0;
  right: 350px;
  bottom: 0;
  z-index: 4;
  pointer-events: auto;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0s 0.3s, background-color 0s;
  transition-delay: 0s, 0.3s, 0s;
  padding-left: 3.75rem;
  padding-right: 3.75rem;
  background: #fff;
  transition: all 0.6s ease-out;
}
.map-cols .map-info.hide {
  padding: 0;
}
.map-cols .map-info .close-btn {
  color: #212121;
  font-size: 1.2rem;
  position: absolute;
  right: 50px;
  top: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0.9;
  background: #d5ece2;
  text-align: center;
  border-radius: 50%;
}
.map-cols .map-info .close-btn:hover {
  opacity: 1;
  text-decoration: none;
  background: #b1ccb4;
}
.map-cols .map-info div {
  padding-top: 50px;
}

.banner-pic {
  max-height: 370px;
  overflow: hidden;
  position: relative;
}
.banner-pic img {
  width: 100%;
  object-fit: cover;
}

.banner-inner {
  position: absolute;
  z-index: 9;
  top: 25%;
  width: 100%;
}
.banner-inner h1 {
  color: #fff;
}

.blog-latest-entries article {
  margin-bottom: 40px;
}
@media only screen and (min-width: 56.25em) {
  .blog-latest-entries article {
    margin-bottom: 80px;
  }
}
.blog-latest-entries ul.post-detail {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
  font-style: italic;
}
.blog-latest-entries .post-item.post-detail .blog-visual {
  margin: 25px auto 50px;
}
.blog-latest-entries .blog-visual img {
  height: 200px;
  object-fit: cover;
}

.home #news-section .cols-4 {
  grid-gap: 2.5rem;
}
.home .blog-latest-entries {
  /*article:first-child {
      grid-column: 1/2;
      grid-row: 1/6;
      .post-excerpt {
          display: block;
      }
      .cols-2-news {
          display: block;
      }
      img {
          height: auto;
          width: 100%;
      }

      h3 {
       font-size: 24px;
      }
  }*/
  /*  article:nth-child(2) {
       grid-row: 1;
   }
   article:nth-child(3) {
       grid-row: 2;
   }
   article:nth-child(4) {
       grid-row: 3;
   }
   article:nth-child(5) {
       grid-row: 4;
   } */
}
.home .blog-latest-entries article {
  /*  grid-column: 2/3; */
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.home .blog-latest-entries article .post-excerpt {
  display: none;
}
.home .blog-latest-entries article img {
  width: 120px;
  object-fit: cover;
  height: 120px;
  transition: all 0.6s ease-in-out;
}
.home .blog-latest-entries article:hover img {
  transform: scale(1.1);
}
.home .blog-latest-entries article h3 {
  font-size: 22px;
  margin: 5px 0 0;
}

.blog-date-wrap {
  display: block;
  margin-top: 5px;
}

@media only screen and (min-width: 56.25em) {
  .post-item.post-detail .blog-content {
    padding: 20px 0;
  }
}
.post-item.post-detail .blog-date-wrap.wrapper {
  border-left: 5px solid #377561;
}

.blog-content .post-detail {
  list-style-type: none;
  padding-left: 0;
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.blog-content .post-detail li {
  float: left;
  margin-right: 10px;
}

.news-row {
  position: relative;
}
.news-row a {
  color: #333;
  position: relative;
  cursor: pointer;
  background: 0 0;
  display: block;
  text-decoration: none;
}
.news-row img {
  -webkit-backface-visibility: hidden;
  -ms-transform: translateZ(0);
  /* IE 9 */
  -webkit-transform: translateZ(0);
  /* Chrome, Safari, Opera */
  transform: translateZ(0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.news-row h3 {
  color: #333;
  margin-top: 0;
}
.news-row:hover h3 {
  color: #55c1aa;
}

.app-blog .blog-visual img {
  width: 100%;
}

.blog-visual {
  overflow: hidden;
}

.page-header {
  color: #fff;
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  max-height: 780px;
  padding: 120px 0 20px;
  background: #31423d;
  margin: 0;
}
@media only screen and (min-width: 56.25em) {
  .page-header {
    padding: 180px 0 80px;
  }
}
.page-header img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.3;
  height: 100%;
  object-fit: cover;
}

.page-container {
  overflow: hidden;
  padding-bottom: 50px;
}

.blog-date {
  color: rgba(0, 0, 0, 0.54);
}

@media screen and (max-width: 480px) {
  article {
    margin-bottom: 40px;
  }
}
.dark-theme {
  background: #2d2d2d !important;
}
.dark-theme p, .dark-theme h1, .dark-theme h2, .dark-theme h3, .dark-theme h4, .dark-theme h5, .dark-theme span, .dark-theme a {
  color: #fff;
}
.dark-theme .blog-date-wrap span {
  color: #55c1aa;
}

.blog-list .post-item {
  margin-bottom: 60px;
}

::-moz-selection {
  background-color: #cdddd8;
}

::selection {
  background-color: #cdddd8;
}