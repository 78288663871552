// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

@media all and (min-width: 951px) {
    .site-logo img {
        position: absolute;
        width: 18%;
    }
}

@media all and (min-width: 1350px) {
     .site-logo img {
        max-width: 320px;
    }
}