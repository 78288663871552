#calendar {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100%;
  height: auto;
  @include breakpoint(md) {
      width: 420px;
      height: 620px;
  }
  overflow: hidden;

    .header {
      width: 100%;
      height: auto;
      @include breakpoint(md) {
          height: 50px;
          width: 420px;
      }
      border: 1px solid $calendar;
      text-align: center;
      position:relative;
      z-index: 100;
    }

    .header h1 {
      color: #000;
      margin: 0;
      padding: 0;
      font-size: 20px;
      line-height: 50px;
      letter-spacing: 1px;
    }

    .left, .right {
      position: absolute;
      width: 0px;
      height: 0px;
      border-style: solid;
      top: 50%;
      margin-top: -7.5px;
      cursor: pointer;
    }

    .left {
      border-width: 7.5px 10px 7.5px 0;
      border-color: transparent rgba(0, 0, 0, 1) transparent transparent;
      left: 20px;
    }

    .right {
      border-width: 7.5px 0 7.5px 10px;
      border-color: transparent transparent transparent rgba(0, 0, 0, 1);
      right: 20px;
    }

    .month {
        background: #fff;
        overflow: hidden;
        opacity: 0;
    }

    .month.new {
      -webkit-animation: fadeIn 1s ease-out;
      opacity: 1;
    }

    .month.in.next {
      -webkit-animation: moveFromTopFadeMonth .4s ease-out;
      -moz-animation: moveFromTopFadeMonth .4s ease-out;
      animation: moveFromTopFadeMonth .4s ease-out;
      opacity: 1;
    }

    .month.out.next {
      -webkit-animation: moveToTopFadeMonth .4s ease-in;
      -moz-animation: moveToTopFadeMonth .4s ease-in;
      animation: moveToTopFadeMonth .4s ease-in;
      opacity: 1;
    }

    .month.in.prev {
      -webkit-animation: moveFromBottomFadeMonth .4s ease-out;
      -moz-animation: moveFromBottomFadeMonth .4s ease-out;
      animation: moveFromBottomFadeMonth .4s ease-out;
      opacity: 1;
    }

    .month.out.prev {
      -webkit-animation: moveToBottomFadeMonth .4s ease-in;
      -moz-animation: moveToBottomFadeMonth .4s ease-in;
      animation: moveToBottomFadeMonth .4s ease-in;
      opacity: 1;
    }

    .week {
     background: #ffffff;
    }

    .day {
      display: inline-block;
      border: 1px solid transparent;
      width: 45px;
      @include breakpoint(md){
        width: 60px;
      }
      padding: 10px;
      text-align: center;
      vertical-align: top;
      cursor: pointer;
      background: #ffffff;
      position: relative;
      z-index: 100;
    }

    .day.other {
     color: #fff;
    }

    .day.today,
    .day.selected {
      color: $color-2;
    }

    .day.selected {
        border :1px solid $color-2;
    }

    .day-name {
      font-size: 9px;
      text-transform: uppercase;
      margin-bottom: 5px;
      color: #000;
      letter-spacing: .7px;
    }

    .day-number {
      font-size: 22px;
      @include breakpoint(md) {
        font-size: 24px;
      }
      letter-spacing: 1.5px;
      &:hover {
        color: $color-2;
      }
    }

    .day .day-events {
      list-style: none;
      margin-top: 3px;
      text-align: center;
      height: 12px;
      line-height: 6px;
      overflow: hidden;
    }

    .day .day-events span {
      vertical-align: top;
      display: inline-block;
      padding: 0;
      margin: 0;
      width: 5px;
      height: 5px;
      line-height: 5px;
      margin: 0 1px;
    }

    .blue { background: rgba(156, 202, 235, 1); }
    .orange { background: rgba(247, 167, 0, 1); }
    .green { background: $color-2; }
    .yellow { background: rgba(249, 233, 0, 1); }

    .arrow {
      display: none;
      position: absolute;
      top: -5px;
      left: 50%;
      margin-left: -2px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent rgba(164, 164, 164, 1) transparent;
      transition: all 0.7s ease;
    }

    .legend {
      display: none;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30px;
      background: rgba(60, 60, 60, 1);
      line-height: 30px;

    }

    .entry {
      position: relative;
      padding: 0 0 0 25px;
      font-size: 13px;
      display: inline-block;
      line-height: 30px;
      background: transparent;
    }

    .entry:after {
      position: absolute;
      content: '';
      height: 5px;
      width: 5px;
      top: 12px;
      left: 14px;
    }

    .entry.blue:after { background: rgba(156, 202, 235, 1); }
    .entry.orange:after { background: rgba(247, 167, 0, 1); }
    .entry.green:after { background: $color-2; }
    .entry.yellow:after { background: rgba(249, 233, 0, 1); }
}

.details {
  position: absolute;
  width: 420px;
/*       background: rgba(164, 164, 164, 1); */
  margin-top: 5px;
  border-radius: 4px;
  top: 0;
  left: 500px;
}

.details.in {
  -webkit-animation: moveFromTopFade .5s ease both;
  -moz-animation: moveFromTopFade .5s ease both;
  animation: moveFromTopFade .5s ease both;
}

.details.out {
  -webkit-animation: moveToTopFade .5s ease both;
  -moz-animation: moveToTopFade .5s ease both;
  animation: moveToTopFade .5s ease both;
}

.events {
  min-height: 75px;
  padding: 7px 0;
}

.events.in {
  -webkit-animation: fadeIn .3s ease both;
  -moz-animation: fadeIn .3s ease both;
  animation: fadeIn .3s ease both;
}

.events.in {
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  animation-delay: .3s;
}

.details.out .events {
  -webkit-animation: fadeOutShrink .4s ease both;
  -moz-animation: fadeOutShink .4s ease both;
  animation: fadeOutShink .4s ease both;
}

.events.out {
  -webkit-animation: fadeOut .3s ease both;
  -moz-animation: fadeOut .3s ease both;
  animation: fadeOut .3s ease both;
}

.event {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: .5px;
  padding: 2px 16px;
  vertical-align: top;
  border-bottom: 1px solid #DDD;
  padding: 50px 0;
  display: block;
}

.event.empty {
  color: #eee;
}

.event-category {
  height: 10px;
  width: 10px;
  display: inline-block;
  margin: 6px 0 0;
  vertical-align: top;
}

.event span {
  display: inline-block;
  padding: 0 0 0 7px;
}

.event-details {
    padding: 40px 0;
    border-bottom: 1px solid $lightgray;

    h3 {
        margin: 0 auto 20px;
    }
    img {
        object-fit: cover;
        width: 100%;
    }

    hr {
        height: 2px;
        color: $calendar;
        background-color: $calendar;
        margin: 5px auto;
    }

    .description {
        padding: 20px 0;
    }
}

.event-details-wrap {
    .event-details:first-child {
        padding-top: 0;
    }
}

.event-info {
    color: $color-2;
    letter-spacing: 1px;
    text-transform: uppercase;
}

/* Animations are cool!  */
@-webkit-keyframes moveFromTopFade {
  from { opacity: .3; height:0px; margin-top:0px; -webkit-transform: translateY(-100%); }
}
@-moz-keyframes moveFromTopFade {
  from { height:0px; margin-top:0px; -moz-transform: translateY(-100%); }
}
@keyframes moveFromTopFade {
  from { height:0px; margin-top:0px; transform: translateY(-100%); }
}

@-webkit-keyframes moveToTopFade {
  to { opacity: .3; height:0px; margin-top:0px; opacity: 0.3; -webkit-transform: translateY(-100%); }
}
@-moz-keyframes moveToTopFade {
  to { height:0px; -moz-transform: translateY(-100%); }
}
@keyframes moveToTopFade {
  to { height:0px; transform: translateY(-100%); }
}

@-webkit-keyframes moveToTopFadeMonth {
  to { opacity: 0; -webkit-transform: translateY(-30%) scale(.95); }
}
@-moz-keyframes moveToTopFadeMonth {
  to { opacity: 0; -moz-transform: translateY(-30%); }
}
@keyframes moveToTopFadeMonth {
  to { opacity: 0; -moz-transform: translateY(-30%); }
}

@-webkit-keyframes moveFromTopFadeMonth {
  from { opacity: 0; -webkit-transform: translateY(30%) scale(.95); }
}
@-moz-keyframes moveFromTopFadeMonth {
  from { opacity: 0; -moz-transform: translateY(30%); }
}
@keyframes moveFromTopFadeMonth {
  from { opacity: 0; -moz-transform: translateY(30%); }
}

@-webkit-keyframes moveToBottomFadeMonth {
  to { opacity: 0; -webkit-transform: translateY(30%) scale(.95); }
}
@-moz-keyframes moveToBottomFadeMonth {
  to { opacity: 0; -webkit-transform: translateY(30%); }
}
@keyframes moveToBottomFadeMonth {
  to { opacity: 0; -webkit-transform: translateY(30%); }
}

@-webkit-keyframes moveFromBottomFadeMonth {
  from { opacity: 0; -webkit-transform: translateY(-30%) scale(.95); }
}
@-moz-keyframes moveFromBottomFadeMonth {
  from { opacity: 0; -webkit-transform: translateY(-30%); }
}
@keyframes moveFromBottomFadeMonth {
  from { opacity: 0; -webkit-transform: translateY(-30%); }
}

@-webkit-keyframes fadeIn  {
  from { opacity: 0; }
}
@-moz-keyframes fadeIn  {
  from { opacity: 0; }
}
@keyframes fadeIn  {
  from { opacity: 0; }
}

@-webkit-keyframes fadeOut  {
  to { opacity: 0; }
}
@-moz-keyframes fadeOut  {
  to { opacity: 0; }
}
@keyframes fadeOut  {
  to { opacity: 0; }
}

@-webkit-keyframes fadeOutShink  {
  to { opacity: 0; padding: 0px; height: 0px; }
}
@-moz-keyframes fadeOutShink  {
  to { opacity: 0; padding: 0px; height: 0px; }
}
@keyframes fadeOutShink  {
  to { opacity: 0; padding: 0px; height: 0px; }
}


.c-event_left {
    min-height: 250px;
    padding-bottom: 28%;
    position: relative;
}
.o-background {
    display: block;
    background-position: 50%;
    background-size: cover;
}


.o-background, .o-background.-overlay::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.c-event_image_half {
    width: 50%;
}

.c-event_date {
    display: inline-block;
    background-color: $calendar;
    text-align: center;
    font-family: Larsseit,sans-serif;
    position: absolute;
    right: 0;
    bottom: 0;
}
.c-event_date_half {
    height: 50%;
    &:before {
        display: inline-block;
        height: 100%;
        content: "";
        vertical-align: middle;
    }
    &:first-child {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            border-bottom: 2px solid;
            opacity: .1;
        }
    }
    &:last-child {
        padding-top: 10%;
    }

    > * {
        display: inline-block;
        vertical-align: middle;
    }
}

.c-event_date {
    width: 50%;
    height: 100%;
    top: 0;
}

.c-event_date_arrow {
    position: absolute;
    bottom: -.5625rem;
    left: 50%;
    margin-left: -.5625rem;
    width: 1.125rem;
    height: 1.125rem;
    fill: #000;
}

.upcoming-events {
    h3 {
        margin-top: 0;
    }
}