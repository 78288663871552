/* sidebar */
.dashboard {

    .page-content {
        padding: 100px 0;
         @include breakpoint(md){
            /* background: linear-gradient(to left, $color-bkg 0%,$color-bkg 50%,#f4f7fc 75%,white 40%,white 40%); */
            padding: 100px 0 150px;
         }
    }

    .ksw-form {
        background: #fff;
        border: 0px solid #fff;
        box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, .2);
    }

    .sidebar {
        border-radius: 10px;
        position: relative;
        @include breakpoint(lg) {
            left: -60px;
            min-height: 600px;
        }

        .sidebar-inner {
           background: white;
           padding: 30px 10px;
           z-index: 1;
           @include breakpoint(md) {
            position: fixed;
            width: 235px;
           }
        }

        h3 {
            text-transform: uppercase;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                border-bottom: 1px solid $lightgray;
                padding: 8px 0 8px 10px;

                &:hover {
                    background: $lightgray;
                }

                a:hover {
                    text-decoration: none;
                    span {
                        text-decoration: underline;
                    }
                }

                &.active {
                    background: $color-2;
                    a, i {
                        color: #fff;
                    }
                }

                a {
                    color: $color-1;
                }

                i {
                    color: $color-1;
                    margin-right: 10px;
                }
            }
        }
    }

    .side-content {
        order: 4;
    }
}

/* main content */
.box-link {
    background: #fff;
    cursor: pointer;
    box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, .2);
    border-radius: 10px;
    padding: 2rem;
    margin-bottom: 40px;
    text-align: center;
    position: relative;
    width: 200px;
    height: 200px;
    &:hover {
        box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, .2);
        -webkit-box-shadow: 0 30px 60px -12px rgba(50, 50, 93, .25), 0 18px 36px -18px rgba(0, 0, 0, .3), 0 -12px 36px -8px rgba(0, 0, 0, .025);
        box-shadow: 0 30px 60px -12px rgba(50, 50, 93, .25), 0 18px 36px -18px rgba(0, 0, 0, .3), 0 -12px 36px -8px rgba(0, 0, 0, .025);
        top: -2px;
    }

    h3 {
        font-weight: bold;
    }

    i {
        font-size: 1.5rem;
        padding:20px;
        border:1px solid #ddd;
        border-radius: 50%;
        width: 70px;
        height: 70px;
    }
}

.boxed-link.dashboard {
    box-shadow: 5px 5px 25px 0 rgba(46, 61, 73, .2);
    position: relative;
    height: auto;
    min-height: 270px;
    margin: 0;
    text-align: left;
    margin-bottom: 30px;
    height: 100%;
    border: 1px solid #fff;
    background: #fff;
    &:hover {
        box-shadow: 2px 4px 8px 0 rgba(46, 61, 73, .2);
        -webkit-box-shadow: 0 30px 60px -12px rgba(50, 50, 93, .25), 0 18px 36px -18px rgba(0, 0, 0, .3), 0 -12px 36px -8px rgba(0, 0, 0, .025);
        box-shadow: 0 30px 60px -12px rgba(50, 50, 93, .25), 0 18px 36px -18px rgba(0, 0, 0, .3), 0 -12px 36px -8px rgba(0, 0, 0, .025);
        top: -2px;
    }
    .img-wrapper {
        margin: 30px 0;
    }

    &.add-new-adress {
        min-height: 100px;
        height: 180px;
    }

    h3 {
        color: $black;
    }

    .button-wrapper {
        background: $color-2;
        position: absolute;
        bottom: 0;
        height: 70px;
        width: 100%;
        text-align: center;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        display: grid;
        align-items: center;
        a {
            height: auto;
            position: absolute;
            top: 50%;
            left: 45%;
            transform: translate(-45%, -50%);
            background: none;
            border: 0px solid #fff;
            color: #fff;
            width: auto;
            letter-spacing: 1px;

             @media screen and (min-width:900px) and (max-width:1200px) {
                font-size: 0.8rem;
             }
        }
        &:hover {
            background: $lightgreen;
        }
    }

    .header-wrapper {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        padding: 20px;
        border-bottom: 1px solid $lightgray;
        h4 {
            margin-top: 0;
        }
    }
}

.section {
    padding: 20px 0;
    @include breakpoint(md) {
        padding: 50px 0;
    }
}

.cart {
    width: 100%;
    padding: 30px;
    border-left: 1px solid #ddd;
    height: 100%;
    text-align: center;

    @media screen and (max-width:480px) {
        border-bottom: 1px solid #ddd;
    }

    h4 {
        border-bottom: 3px solid #000;
    }

    .button-wrapper {
        margin: 40px 0;
        text-align: center;
    }

    .img-wrapper {
        font-weight: bold;
        text-align: center;
    }
}

.order-listing {
    @include breakpoint(md) {
        padding: 20px;
    }
}

.order-item-list {
    box-shadow: 0 30px 60px -12px rgba(50, 50, 93, .25), 0 18px 36px -18px rgba(0, 0, 0, .3), 0 -12px 36px -8px rgba(0, 0, 0, .025);
    border-radius: .375rem;
    .order-item {
        background: #fff;
        padding: 30px 20px;
        @include breakpoint(md) {
            transition: $transition;
            transform: perspective(1px) scale(1) translateZ(0);
            backface-visibility: hidden;
            -webkit-font-smoothing: subpixel-antialiased;

        }
        &:nth-child(even) {
            background: $color-bkg;
        }
        &:first-child {
            border-collapse: separate;
            border-top-left-radius: .375rem;
            border-top-right-radius: .375rem;
        }
         &:last-child {
            border-collapse: separate;
            border-bottom-left-radius: .375rem;
            border-bottom-right-radius: .375rem;
        }
        &:hover {
            border-radius: .375rem;
            position: relative;
            z-index:9999;
            transform:  scale(1.028);
        }

        span {
            color: #8A8383;
            font-size: .9em;
            letter-spacing: 1px;
        }

        .order-value {
            color: $color-2;
        }

         .pdf {
             margin-top: 30px;
         }
         @include breakpoint(md) {
            .pdf {
                margin-top: -11px;
            }
         }
    }

    .lnr-checkmark-circle {
        color: $color-2;
        padding-right: 8px;
    }

    .lnr-warning {
        color: $red;
        padding-right: 8px;
    }

    input[type="checkbox"] {
        width: 20px;
        height: 20px;
    }
}

.total-pay {
    border: 0 !important;
    background: transparent !important;
    display: inline-block;
}

#twispayframe {
    height: 100%;
    background: rgba(0,0,0,0.5);
}

.info-box-bordered {
    border:1px solid #222;
    padding: 2px 10px;
    margin-left: 10px;
}

.add-new {
    font-size: 2rem;
}

.formsets .ksw-form {
    margin-bottom: 20px;
}

.download-icon {
    &:hover {
        h5 {
            color: $color-2;
        }
    }
}

/* Mobile navigation */
@media screen and (max-width:760px) {
    .sidebar-dashboard {
        background: white;
        height: 100vh;
        width: 50px;
        .sidebar-inner {
            background: #fff;
            left: 0;
            height: 100vh;
            position: fixed;
            padding-top: 0 !important;
            transition: $transition;
            .dashboard-links {
                transition: $transition;
                li {
                    border-bottom: 1px solid #ddd;
                    padding: 10px 0 10px 10px;
                    i {
                        font-size: 2rem;
                    }
                    span {
                        display: none;
                        position: relative;
                        top: -5px;
                    }
                }
            }

            &.in {
                .dashboard-links {
                    li {
                        span {
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .expand-menu {
            text-decoration: none !important;
            outline: none !important;
        }
    }

    .dashboard {
        .page-content > .wrapper {
            padding-left: 0;
        }
        .page-content {
            background: #f3f6fb !important;
            padding: 70px 0;
        }
        .cols-small-sidebar-left {
            grid-template-columns: 80px auto;
        }
        .side-content {
            .ksw-form {
                margin-bottom: 30px;
            }
        }
    }
}

.expand-menu {
    display: none;
}

@media screen and (max-width:480px) {
    .expand-menu {
        display: block;
    }
    .dashboard {
        #footer {
            padding-left: 70px;
        }
    }
    .footer-img {
        position: absolute;
        top: -50px;
        left: 0;
    }
}

.index-box {
    border:1px solid #222;
    padding: 2px 10px;
    margin-left: 10px;
}