// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
::-moz-selection {
  background-color: $color__background_selection;
}

::selection {
  background-color: $color__background_selection;
}